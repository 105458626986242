.dropdown-box {
  width: 220px;
  border-radius: 10px;
  background-color: #fff;
  box-shadow: 3px 5px 40px 0 rgba(94, 92, 154, 0.06);
  position: relative;

  &.header-dropdown {
    position: absolute;
    z-index: 9999;
    top: 64px;
    right: 6px;
    opacity: 0;
    visibility: hidden;
    transform: translate(0, -40px);
    transition:
      transform 0.4s ease-in-out 0s,
      opacity 0.4s ease-in-out 0s,
      visibility 0.4s ease-in-out 0s;

    &.active {
      opacity: 1;
      visibility: visible;
      transform: translate(0px, 0px);
    }
  }
}

.dropdown-navigation {
  padding: 20px 28px;
  border-radius: 10px;
  background-color: #fff;
  box-shadow: 3px 5px 40px 0 rgba(94, 92, 154, 0.06);

  .dropdown-navigation-header + .dropdown-navigation-category {
    margin-top: 30px;
  }

  .dropdown-navigation-category {
    margin: 20px 0 10px;
    color: #adafca;
    font-size: 0.75rem;
    font-weight: 700;
    text-transform: uppercase;

    @media screen and (max-width: 1366px) {
      font-size: 0.6875rem;
    }
  }

  .dropdown-navigation-button {
    width: 100%;
    margin-top: 20px;
  }

  .dropdown-navigation-link {
    display: block;
    padding: 8px 0;
    font-size: 0.875rem;
    font-weight: 700;
    transition:
      padding 0.2s ease-in-out,
      color 0.2s ease-in-out;

    @media screen and (max-width: 1366px) {
      font-size: 0.75rem;
      padding: 6px 0;
    }

    &:hover {
      padding-left: 4px;
      color: #00c7d9;
    }

    .highlighted {
      float: right;
      color: #00c7d9;
    }

    img {
      vertical-align: middle;
    }
  }
}
