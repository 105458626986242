[data-tooltip] {
  position: relative;
  &:hover::before {
    opacity: 1;
    visibility: visible;
  }
  &::before {
    content: attr(data-tooltip);
    background-color: rgba(62, 63, 94, 1);
    border-radius: 100px;
    color: #fff;
    visibility: hidden;
    opacity: 0;
    max-width: 250px;
    display: inline-block;
    font-size: 12px;
    font-family: 'Quicksand';
    transition: opacity 0.2s ease-in;
    line-height: 15px;
    padding: 2px 15px;
    position: absolute;
    left: 100%;
    z-index: 123;
    margin-right: -500%;
    top: 50%;
    transform: translateY(-50%);
  }
}

/* Específico para data-placement="left" */
[data-tooltip][data-type='list']::before {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 10px;
  border-radius: 4px;
  white-space: pre-wrap;
}

/* Específico para data-placement="left" */
[data-tooltip][data-placement='left']::before {
  left: -120px;
}

/* Específico para data-placement="bottom" */
[data-tooltip][data-placement='bottom']::before {
  transform: translateX(-100%) translateY(150%);
}
