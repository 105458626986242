.theme-lugarh {
  &__link {
    text-decoration: underline;
    font-size: 14px;
    opacity: 0.6;
    font-weight: 600;
    @extend .default-font;

    &:hover {
      color: var(--primary-color);
    }
  }
}
