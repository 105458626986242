@import '../../assets/styles/variables.scss';

.tag-list {
  display: -ms-flexbox;
  display: flex;
  gap: 10px;
  align-items: center;

  .tag-item {
    display: flex;

    max-width: 110px;

    position: relative;

    gap: 10px;

    overflow: visible;

    & .tag-list-hidden {
      position: absolute;
      top: calc(100% - 5px);
      left: 50%;
      transform: translateX(-50%);

      background-color: rgba($color: #000000, $alpha: 0.8);

      padding: 10px;
      border-radius: 4px;
      z-index: 999;

      display: flex;
      flex-direction: column;

      gap: 5px;

      & > span {
        color: #fff;
        white-space: nowrap;

        font-size: 10px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
      }
    }
  }

  .tag-item-recruiter {
    color: $tertiary-color;
  }
  .tag-item-recruiter-count {
    color: $tertiary-color;
  }

  .tag-item-button {
    margin: 12px -10px 0 0;
    z-index: 97;
    color: $tertiary-color;
  }

  .tag-item-left {
    margin: 12px -10px 0 0;
    z-index: 97;
    color: $tertiary-color;
  }
}

.tag-item-button {
  display: block;
  height: 24px;
  padding: 0 12px;
  border-top-left-radius: 200px;
  border-bottom-left-radius: 200px;
  background-color: rgba($primary-color, 0.2);
  font-size: 0.75rem;
  font-weight: 700;
  line-height: 24px;
  cursor: pointer;
}

.tag-item-recruiter {
  display: block;
  height: 24px;
  padding: 0 12px;
  border-radius: 200px;
  font-size: 0.75rem;
  font-weight: 700;
  line-height: 24px;
  background-color: rgba($primary-color, 0.2);

  white-space: nowrap;

  text-overflow: ellipsis;
  overflow: hidden;

  &.tag-item-right {
    border-radius: 0;
    padding: 0 12px 0px 0px;
    border-top-right-radius: 200px;
    border-bottom-right-radius: 200px;
  }

  & ~ .tag-item-left {
    border-radius: 0;
    border-top-right-radius: 200px;
    border-bottom-right-radius: 200px;
  }
}

.tag-item-recruiter-count {
  display: block;
  height: 24px;
  padding: 0 12px;
  border-radius: 200px;
  font-size: 0.75rem;
  font-weight: 700;
  line-height: 24px;
  background-color: rgba($primary-color, 0.2);

  white-space: nowrap;

  text-overflow: ellipsis;
  overflow: hidden;

  & ~ .tag-item-left {
    border-radius: 0;
    border-top-right-radius: 200px;
    border-bottom-right-radius: 200px;
  }
}

.tag-item-left {
  display: block;
  height: 24px;
  padding: 0 12px;
  border-top-left-radius: 200px;
  border-bottom-left-radius: 200px;
  background-color: rgba($primary-color, 0.2);
  font-size: 0.75rem;
  font-weight: 700;
  line-height: 24px;
}
