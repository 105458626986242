.loader-bars {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
  justify-content: center;
  height: 40px;

  .bar {
    width: 4px;
    height: 100%;
    margin-right: 4px;
    border-radius: 200px;
    -webkit-transform: scaleY(0.2);
    transform: scaleY(0.2);
    -webkit-animation: loader-bars 0.5s infinite alternate ease-in;
    animation: loader-bars 0.5s infinite alternate ease-in;
  }

  .bar:last-child {
    margin-right: 0;
  }

  .bar:nth-child(1) {
    background-color: #615dfa;
  }

  .bar:nth-child(2) {
    background-color: #5d71fb;
    -webkit-animation-delay: 0.1s;
    animation-delay: 0.1s;
  }

  .bar:nth-child(3) {
    background-color: #5983fb;
    -webkit-animation-delay: 0.2s;
    animation-delay: 0.2s;
  }

  .bar:nth-child(4) {
    background-color: #549afc;
    -webkit-animation-delay: 0.3s;
    animation-delay: 0.3s;
  }

  .bar:nth-child(5) {
    background-color: #4eb2fd;
    -webkit-animation-delay: 0.4s;
    animation-delay: 0.4s;
  }

  .bar:nth-child(6) {
    background-color: #49c9fe;
    -webkit-animation-delay: 0.5s;
    animation-delay: 0.5s;
  }

  .bar:nth-child(7) {
    background-color: #45ddfe;
    -webkit-animation-delay: 0.6s;
    animation-delay: 0.6s;
  }

  .bar:nth-child(8) {
    background-color: #41efff;
    -webkit-animation-delay: 0.7s;
    animation-delay: 0.7s;
  }
}

@-webkit-keyframes loader-bars {
  0% {
    -webkit-transform: scaleY(0.2);
    transform: scaleY(0.2);
  }

  100% {
    -webkit-transform: scaleY(1);
    transform: scaleY(1);
  }
}

@keyframes loader-bars {
  0% {
    -webkit-transform: scaleY(0.2);
    transform: scaleY(0.2);
  }

  100% {
    -webkit-transform: scaleY(1);
    transform: scaleY(1);
  }
}
