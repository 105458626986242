.card-plan-area {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: stretch;
  align-content: stretch;
}

.card-plan {
  width: 300px;
  background: #f4f4f4;
  max-height: 450px;
  border-radius: 14px;
  margin: 10px;
  padding: 0 15px 0 15px;
}

.card-plan-title h1 {
  font-size: 30px;
  text-align: center;
  padding-top: 50px;
}

.card-plan-title h2 {
  font-size: 16px !important;
  text-align: center !important;
  padding-top: 12px;
  font-weight: 10;
  min-height: 48px;
}

.card-plan-price {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: baseline;
  align-content: stretch;
  width: 228px;
}

.card-plan-value {
  font-size: 45px;
  color: #045089;
  margin: 20px 0 10px 0;
}

.card-plan-period {
  color: #0f588e;
}

.list-items {
  height: 150px;
}

.list-items > ul {
  text-align: center;
}

.list-items > ul > li {
  border-bottom: 1px solid #e7e7e7;
  margin-top: 15px;
}

.card-plan-button {
  padding: 0 15px;
  margin-top: 15px;
  padding-bottom: 15px;
}
