.title {
  font-weight: bold;
  font-size: 20px;
  line-height: 26px;
  color: #3e3f5e;
}

.add-button {
  max-width: 95px;
}
