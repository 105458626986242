.select-wrapper {
  position: relative;
  width: 100%;

  @media screen and (max-width: 425px) {
    width: 190px;
  }

  label {
    padding: 0 6px;
    background-color: #fff;
    color: #adafca;
    font-size: 0.875rem;
    font-weight: 600;
    position: absolute;
    top: -8px;
    left: 12px;
    pointer-events: none;
  }

  select {
    height: 48px;
    padding-left: 20px;
    cursor: pointer;
    padding-right: 40px;
  }

  svg {
    fill: #3e3f5e;
    transform: rotate(90deg);
    width: 6px;
    height: 8px;
    position: absolute;
    margin-top: 20px;
    margin-left: -30px;
    cursor: pointer;
    pointer-events: none;
  }

  &[data-empty='true'] {
    label {
      top: 50%;
      transform: translateY(-50%);
    }
  }
}
