.workflow-step-wrapper {
  display: grid;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  margin: 10px;
  padding: 20px 0;
  grid-template-rows: 1;
  grid-auto-flow: column;
  grid-template-columns: auto 1fr;
  grid-gap: 15px;
  align-items: center;

  .content {
    .title {
      font-size: 0.875rem;
      font-weight: 600;
      margin: 0;
    }

    .description {
      margin-top: 6px;
      font-size: 0.75rem;
      font-weight: 400;
      position: relative;
    }

    &:last-child {
      border: none;
    }
  }

  .add-workflow-step {
    padding: 0 16px;

    border-radius: 4px;
    background-color: #ffb300;
    color: black;
  }
}

.primary-custom {
  border-radius: 4px;
  background: rgba(25, 118, 210, 0.12) !important;

  box-shadow: none !important;

  color: #1976d2 !important;
  text-align: center;
  font-family: Quicksand;
  font-size: 11.783px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: capitalize;
}

.btn-delete {
  border-radius: 4px;
  height: 40px !important;
  width: 70px !important;
  box-shadow: 0px 0px 4px 0px #00000040 !important;

  background-color: white !important;

  font-size: 18px;

  svg {
    width: 14px;
    height: 18px;
    > path {
      stroke: #1976d2 !important;
    }
  }

  &[data-tooltip] {
    &::before {
      width: 100px;
      max-width: initial;
      margin-right: initial;
      left: initial;
      right: 110%;
      padding: 10px 15px;
    }
  }
}

.btn-blocked {
  &:disabled {
    background-color: transparent !important;
    opacity: 0.5;
  }

  &[data-tooltip] {
    &::before {
      width: 150px;
      max-width: initial;
      margin-right: initial;
      left: initial;
      right: 110%;
      padding: 10px 15px;
    }
  }
}
