.subscription-info-area {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: stretch;
  align-content: stretch;
  height: 450px;
}

.subscription-info-area h1 {
  font-size: 30px;
}

.plan-info-area {
  width: 40%;
}

.plan-payment-area {
  padding-top: 60px;
}

.plan-text-area-plan-info {
  height: 150px;
  margin-top: 30px;
}

.plan-text-area-next-charge {
  height: 50px;
  margin-top: 30px;
}

.plan-text-area p {
  font-size: 16px;
}

.invoices-table {
  width: 100%;
}

.btn-cancel-subscription {
  width: 300px;
  margin: 10px;
  color: #fff;
  background-color: #bebebe;
  border-color: #bebebe;
}
.btn-cancel-subscription:hover {
  color: #fff !important;
  background-color: #818181;
  border-color: #818181;
}
.btn-cancel-subscription.focus,
.btn-cancel-subscription:focus {
  color: #fff;
  background-color: #818181;
  border-color: #818181;
  box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5);
}
.btn-cancel-subscription.disabled,
.btn-cancel-subscription:disabled {
  color: #fff;
  background-color: #bebebe;
  border-color: #bebebe;
}
.btn-cancel-subscription:not(:disabled):not(.disabled).active,
.btn-cancel-subscription:not(:disabled):not(.disabled):active,
.show > .btn-cancel-subscription.dropdown-toggle {
  color: #fff;
  background-color: #818181;
  border-color: #005cbf;
}
.btn-cancel-subscription:not(:disabled):not(.disabled).active:focus,
.btn-cancel-subscription:not(:disabled):not(.disabled):active:focus,
.show > .btn-cancel-subscription.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5);
}

// MOBILE
@media screen and (max-width: 430px) {
  .subscription-info-area {
    flex-wrap: wrap;
    height: 680px;
  }
}

@media screen and (min-width: 667px) and (max-width: 1080px) {
  .subscription-info-area {
    justify-content: space-between;
    height: 380px;
  }

  .plan-payment-area {
    width: 40%;
  }
}
