.badge {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  align-content: stretch;
}
.m-8 {
  margin-left: 4px;
  margin-top: 10px;
}

.badge-list {
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  align-content: stretch;
  gap: 10px;
  &.-grid {
    flex-direction: column;
  }
  &.-row {
    flex-direction: row;
  }
}

.scale {
  display: block;
  flex-grow: 0;
  flex-shrink: 1;
  flex-basis: auto;
  align-self: auto;
  order: 0;
  font-size: 14px;
  font-weight: 700;
  text-align: center;

  & > p {
    margin: 0;
  }
}

.starts-list {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  align-content: center;
  gap: 5px;
}

/*-----------------------------
    14. BADGE ITEM
-----------------------------*/
.badge-item {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-align: center;
  align-items: center;
  width: 20px;
  height: 20px;
  position: relative;
  margin: 8px;

  &.clickable {
    cursor: pointer;
  }

  img {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
  }

  .badge-item-text {
    color: #fff;
    font-size: 0.6875rem;
    font-weight: 700;
    position: relative;
    top: 1px;
    z-index: 2;
  }
}

.badge-title {
  font-size: 1.125rem;
  font-weight: 700;
  text-align: center;
}
