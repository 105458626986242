/*--------------------------------
    4. CALENDAR EVENTS PREVIEW
---------------------------------*/
.calendar-events-preview {
  padding: 32px 28px;
  border-radius: 12px;
  background-color: #fff;
  box-shadow: 0 0 40px 0 rgba(94, 92, 154, 0.06);

  &.history {
    margin-top: 15px;
    border: 1px solid;
    border-color: #ececec;
  }

  &.small {
    .calendar-events-preview-title {
      font-size: 0.875rem;
      margin: 0;
    }

    .calendar-event-preview-list {
      margin-top: 36px;
    }
  }

  .calendar-events-preview-title {
    font-size: 1.125rem;
    font-weight: 700;
    margin-top: 0;
  }

  .calendar-event-preview-list {
    margin-top: 8px;
    margin: 0;
  }
}

.form.candidate-list {
  width: 200px;
}

/*-------------------------------------
    5. CALENDAR EVENT PREVIEW LIST
-------------------------------------*/
.calendar-event-preview-list {
  .calendar-event-preview {
    margin-bottom: 28px;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

/*--------------------------------
    6. CALENDAR EVENT PREVIEW
---------------------------------*/
.calendar-event-preview {
  padding-left: 72px;
  position: relative;

  &.small {
    .calendar-event-preview-start-time
      .calendar-event-preview-start-time-title {
      font-size: 0.875rem;
      margin: 0;
      text-transform: capitalize;
    }

    .calendar-event-preview-start-time .calendar-event-preview-start-time-text {
      margin: 0;
      margin-top: 4px;
      font-size: 0.75rem;
    }

    .calendar-event-preview-info::before {
      content: '';
      width: 8px;
      height: 8px;
      border: 2px solid transparent;
      top: 2px;
    }

    .calendar-event-preview-info .calendar-event-preview-title {
      font-size: 0.875rem;
    }

    .calendar-event-preview-info .calendar-event-preview-text {
      font-size: 0.75rem;
      line-height: 1.1666666667em;
    }
  }

  &.primary {
    .calendar-event-preview-info::before {
      border-color: #23d2e2;
    }
  }

  &.secondary {
    .calendar-event-preview-info::before {
      border-color: #615dfa;
    }
  }

  .calendar-event-preview-start-time {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
  }

  .calendar-event-preview-start-time .calendar-event-preview-start-time-title {
    font-size: 1rem;
    font-weight: 700;
    text-transform: capitalize;
  }

  .calendar-event-preview-start-time .calendar-event-preview-start-time-text {
    margin-top: 2px;
    font-size: 0.875rem;
    font-weight: 500;
  }

  .calendar-event-preview-info {
    position: relative;
    left: 50px;
    width: 92%;
    textarea {
      width: 80%;
    }
    &::before {
      content: '';
      width: 12px;
      height: 12px;
      border-radius: 50%;
      border: 3px solid transparent;
      position: absolute;
      top: 0;
      left: -24px;
    }

    .calendar-event-preview-title {
      font-size: 1rem;
      font-weight: 700;
      cursor: pointer;
      margin: 0;
    }

    .calendar-event-preview-text {
      margin-top: 6px;
      color: #3e3f5e;
      font-size: 0.875rem;
      font-weight: 500;
      line-height: 1.4285714286em;
    }

    .calendar-event-preview-time {
      margin-top: 14px;
      font-size: 0.875rem;
      font-weight: 500;

      &.bold {
        font-weight: 700;
      }
    }
  }
}
