.theme-lugarh {
  .selectable-list {
    .form__field--search {
      margin-bottom: 21px;
    }

    .form__list-item {
      margin: 0 -17px;
      width: initial;
      padding: 6px 20px;
    }

    .form__list-item-single {
      margin: 0 -20px 0 -20px;
      width: initial;
      background-color: rgba(185, 160, 185, 0.062745098);

      .form__list-title {
        padding: 6px 20px;
      }
    }

    .MuiList-root {
      padding-top: 0;
      padding-bottom: 0;
    }

    .MuiButtonBase-root {
      margin: 0 -18px;
      padding: 6px 20px;
    }
    .MuiListItemIcon-root {
      order: 2;
      min-width: initial;

      .MuiSvgIcon-root {
        font-size: 1rem;
      }
    }
  }
}
