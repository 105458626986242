/*--------------------
    1. POPUP BOX
--------------------*/
.popup-box {
  width: 90%;
  max-width: 984px;
  border-radius: 12px;
  background-color: #fff;
  opacity: 0;
  visibility: hidden;
}

.popup-box.mid {
  max-width: 784px;
}

.popup-box.small {
  max-width: 384px;
}

.popup-box .popup-box-body {
  display: -ms-flexbox;
  display: flex;
}

.popup-box .popup-box-body .popup-box-sidebar {
  width: 36.2244897959%;
  border-right: 1px solid #eaeaf5;
}

.popup-box .popup-box-body .popup-box-sidebar .popup-box-sidebar-footer {
  padding: 28px;
}

.popup-box
  .popup-box-body
  .popup-box-sidebar
  .popup-box-sidebar-footer
  .button {
  margin-bottom: 16px;
}

.popup-box
  .popup-box-body
  .popup-box-sidebar
  .popup-box-sidebar-footer
  .button:last-child {
  margin-bottom: 0;
}

.popup-box .popup-box-body .popup-box-sidebar .user-preview {
  box-shadow: none;
  border-top-right-radius: 0;
}

.popup-box
  .popup-box-body
  .popup-box-sidebar
  .user-preview
  .user-preview-cover {
  border-top-right-radius: 0;
}

.popup-box .popup-box-body .popup-box-sidebar .product-preview {
  box-shadow: none;
  border-top-right-radius: 0;
}

.popup-box
  .popup-box-body
  .popup-box-sidebar
  .product-preview
  .product-preview-image {
  border-top-right-radius: 0;
}

.popup-box .popup-box-body .popup-box-content {
  width: 63.7755102041%;
  border-bottom-right-radius: 12px;
}

.popup-box .popup-box-body .popup-box-content.limited {
  max-height: 620px;
  overflow-y: auto;
}

.popup-box .popup-box-body .popup-box-content .widget-box {
  box-shadow: none;
}

.popup-box .popup-box-title {
  padding: 32px 28px 0;
  font-size: 1rem;
  font-weight: 700;
}

.popup-box .popup-box-subtitle {
  padding: 0 28px;
  margin-top: 6px;
  font-size: 0.875rem;
  font-weight: 700;
}

.popup-box .popup-box-subtitle .light {
  color: #8f91ac;
  font-weight: 500;
}

.popup-box .form {
  margin-top: 32px;
}

.popup-box .form .form-row {
  padding: 0 28px;
}

.popup-box .form .form-uploadables {
  margin-top: 32px;
  padding: 0 28px 40px;
  height: 406px;
  overflow-y: auto;
}

.popup-box .widget-box .form .form-row {
  padding: 0;
}

.popup-box .popup-box-actions {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: end;
  justify-content: flex-end;
  padding: 32px 38px;
  box-shadow: 0 0 40px 0 rgba(94, 92, 154, 0.06);
}

.popup-box .popup-box-actions.void {
  box-shadow: none;
}

.popup-box .popup-box-actions.medium {
  padding: 41px 28px;
}

.popup-box .popup-box-actions.full {
  display: block;
  padding: 40px 28px 28px;
}

.popup-box .popup-box-actions .popup-box-action-text {
  margin-top: 24px;
  font-size: 0.875rem;
  font-weight: 500;
  line-height: 1.4285714286em;
}

.popup-box .popup-box-actions .popup-box-action {
  width: 180px;
  margin-right: 16px;
}

.popup-box .popup-box-actions .popup-box-action.full {
  width: 100%;
}

.popup-box .popup-box-actions .popup-box-action:last-child {
  margin-right: 0;
}
