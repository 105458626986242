.color-picker-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .color-picker-label {
    font-weight: 700;
    color: #3e3f5e;
    text-align: unset;
    height: 30px;
    text-align: center;
  }
}
