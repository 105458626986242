.input-search-container {
  position: relative;
}

.input-search-wrapper {
  position: relative;

  .form-input {
    width: 100%;

    input {
      padding-right: 50px;
    }
  }

  .input-icon {
    width: 20px;
    fill: #adafca;
    opacity: 0.6;
    pointer-events: none;
    position: absolute;
    top: 17px;
    right: 12px;
  }
}

.results {
  background-color: #fff;
  padding: 15px 0;
  border-radius: 10px;
  margin-top: 5px;
  width: 100%;
  position: absolute;
  z-index: 999;
  box-shadow: 0 0 1px 1px #3e3f5e25;
  opacity: 0;
  visibility: hidden;
  transform: translate(0, -15%);
  transition: all 0.2s ease-in;
  overflow-y: auto;
  max-height: 320px;

  &::-webkit-scrollbar {
    width: 14px;
  }

  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 14px 14px transparent;
    border: solid 4px transparent;
  }

  &::-webkit-scrollbar-thumb {
    box-shadow: inset 0 0 14px 14px #bbbbbe;
    border: solid 4px transparent;
    border-radius: 14px;
  }

  &.active {
    opacity: 1;
    visibility: visible;
    transform: translate(0, 0);
  }

  ul {
    li {
      padding: 2.5px 0;
      cursor: pointer;
      padding: 5px 15px;

      &:hover {
        background-color: #eaeaf5;
      }

      p {
        color: #3e3f5e;
        font-family: 'Quicksand', sans-serif;
        line-height: 1em;
        text-align: left;
        font-weight: 700;
        font-size: 0.875rem;
      }
    }
  }
}
