.history-header {
  margin-top: 60px;
  &-info {
    .history-title {
      margin-top: 8px;
      font-size: 1.625rem;
      font-weight: 700;
    }

    .history-pretitle {
      color: #8f91ac;
      font-size: 0.75rem;
      font-weight: 600;
      text-transform: uppercase;
      margin-top: 0;
    }
  }
}
