.button.void.hiring-stage-new:hover {
  background-color: #5749f5;
}
.button.void.hiring-stage-new {
  color: #fff;
  background-color: #afb0c0;
  width: 300px;
  height: 40px;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 50px;
  line-height: 15px;
  padding-top: 12px;
}
