@import '../../../assets/styles/variables.scss';

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-right: 15px;
}

.clearFilterButton {
  background: none;
  width: auto;
  box-shadow: none;
  text-decoration: underline;
  padding: 0;
  color: #950099;
  font-size: $font-size-small;
}
