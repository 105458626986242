@import '../../assets/styles/variables.scss';

.answersWrapper {
  margin-top: 15px;
  border: 1px solid;
  border-color: $border-color;
  padding: 32px 28px;
  border-radius: 12px;
  background-color: $white-color;
  box-shadow: 0 0 40px 0 rgba(94, 92, 154, 0.06);

  .title {
    font-size: 1.125rem;
    font-weight: 700;
    text-align: center;
  }

  .question {
    margin-bottom: 10px;
    margin-top: 30px;
    font-size: 1.02rem;
  }

  .answer {
    position: relative;
    margin-left: 10px;

    p {
      margin: 0px 15px 10px;
    }

    ::before {
      content: '';
      width: 8px;
      height: 8px;
      border: 2px solid transparent;
      border-color: #23d2e2;
      border-radius: 50%;
      position: absolute;
      top: 3px;
      left: 0px;
    }
  }
}
