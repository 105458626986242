.candidate-actions-wrapper {
  margin: 30px 0;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -ms-flex-align: center;
  align-items: center;
  height: 96px;
  padding: 0 28px;
  border-radius: 12px;
  box-shadow: 0 0 40px 0 rgba(94, 92, 154, 0.06);
  background-color: #fff;

  .view-actions {
    display: flex;
    min-width: 500px;

    .select-wrapper {
      margin-right: 24px;
      margin-left: 24px;

      &:last-child {
        &::after {
          display: none;
        }
      }
    }
  }

  .view-type {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;

    svg {
      width: 20px;
      margin: 0 10px;
      cursor: pointer;
      fill: #adafca;
      opacity: 0.4;
      transition:
        opacity 0.2s ease-in-out,
        fill 0.2s ease-in-out;

      &:hover,
      &.active {
        fill: #3e3f5e;
        opacity: 1;
      }
    }
  }

  @media screen and (max-width: 960px) {
    flex-direction: column;
    height: auto;
    padding: 32px 28px;

    .view-type {
      margin-top: 40px;
    }
  }

  @media screen and (max-width: 460px) {
    .view-actions {
      min-width: 250px;
      flex-direction: column;
      .select-wrapper {
        margin: 0;

        &:first-child {
          margin-bottom: 24px;
        }

        &::after {
          display: none;
        }
      }
    }
  }
}
