.theme-lugarh {
  &.list-items-modal {
    .mui-modal {
      &__box {
        width: 500px;
        height: 550px;
      }

      &__header {
        border-bottom: 1px solid #e0e0e0;
      }

      &__content {
        overflow-y: auto;
        height: 471px;
      }

      &__list-item {
        border-bottom: 1px solid #e0e0e0;
        padding: 14px 18px;
      }

      &__list-title {
        font-size: 16px;
        font-weight: 500;
        line-height: 1.375;
        margin: 0;
      }

      &__list-subtitle {
        font-size: 14px;
        line-height: 1.5;
        margin: 0;
        opacity: 0.6;
      }
    }
  }
}
