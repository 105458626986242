@import '../variables.scss';

/*-----------------------------
    9. ACTION LIST
-----------------------------*/
.action-list {
  display: -ms-flexbox;
  display: flex;
  padding: 0 18px;
  position: relative;

  &.dark {
    &::after,
    &::before {
      background-color: $background-color;
    }

    .action-list-item {
      &.white {
        color: #dedeea;
        &:hover,
        &.active,
        &.unread {
          color: white;
          .action-list-item-icon {
            fill: #fff;
          }
        }
      }

      &:hover,
      &.active,
      &.unread {
        .action-list-item-icon {
          fill: #fff;
        }
      }

      .action-list-item-icon {
        fill: $background-color;
      }
    }
  }

  .action-list-item {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    height: 100%;
    padding: 0 14px;
    cursor: pointer;

    &:hover,
    &.active,
    &.unread {
      .action-list-item-icon {
        fill: #3e3f5e;
      }
    }

    &.unread {
      position: relative;

      &::after {
        content: '';
        width: 4px;
        height: 4px;
        border-radius: 50%;
        background-color: #41efff;
        position: absolute;
        top: 26px;
        right: 10px;
      }
    }

    .action-list-item-icon {
      fill: #adafca;
      transition: fill 0.3s ease-in-out;
    }
  }
}

/*-----------------------------
    10. ACTION ITEM
-----------------------------*/
.action-item {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-align: center;
  align-items: center;
  cursor: pointer;

  .action-item-icon {
    fill: #adafca;
  }

  &.dark {
    .action-item-icon {
      fill: #fff;
    }
  }
}
