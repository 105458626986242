@import 'variables';
@import url('https://fonts.googleapis.com/css?family=Titillium+Web:400,900&display=swap');
@import url('https://fonts.googleapis.com/css?family=Quicksand:400,500,600,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');

*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  border: none;
  outline: none !important;
  box-sizing: border-box;
}

*::before,
*::after {
  display: block;
}

ul,
ol {
  list-style: none;
}

a {
  text-decoration: none;
}

select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

h1,
h2,
h3,
h4,
h5,
h6,
a,
p {
  color: #3e3f5e;
  font-family: 'Quicksand', sans-serif;
  line-height: 1em;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 700;
}

a {
  text-decoration: none;

  &:hover {
    text-decoration: none;
    color: #00c7d9;
  }
}

body {
  background-color: #f8f8fb;
}

.jodit-wysiwyg {
  * {
    margin: initial;
    padding: initial;
    border: initial;
    font-size: initial;
    font-style: initial;
    vertical-align: initial;
    font-family: 'Quicksand', sans-serif;
  }

  ul,
  ol {
    margin-top: 10px;
    margin-left: 20px;
  }

  ul {
    list-style-type: disc;
  }

  ol {
    list-style-type: decimal;
  }

  li {
    margin: 3px 0;
  }

  em {
    font-style: italic;
  }

  h4 {
    font-size: 1.5em;
    font-weight: 700;
    margin: 0.7rem 0;
  }

  h3 {
    font-size: 1.75em;
    font-weight: 700;
    margin: 0.8rem 0;
  }

  h2 {
    font-size: 2em;
    font-weight: 700;
    margin: 0.9rem 0;
  }

  h1 {
    font-size: 2.5em;
    font-weight: 700;
    margin: 1rem 0;
  }

  pre {
    display: block;
    background: #f1f1f1;
    padding: 1rem;
    border-radius: 0.33em;
    page-break-inside: avoid;
    font-family: monospace;
    line-height: 1.6;
    max-width: 100%;
    overflow: auto;
    word-wrap: break-word;
  }

  blockquote {
    background: #f9f9f9;
    border-left: 10px solid #ccc;
    margin: 1.5rem 0;
    padding: 0.5rem 1rem;
    font-style: italic;

    &:before {
      color: #a3a3a3;
      content: '\201C';
      font-size: 2em;
      font-style: initial;
      line-height: 0.1rem;
      margin-top: 1rem;
    }

    p,
    span {
      font-style: italic;
    }
  }

  .text-center,
  .text-right,
  .text-left {
    display: block;
  }

  .error {
    display: block;
    background: #ffe0e0;
    color: #ec0001;
    padding: 1rem 1rem 1rem 2.5rem;
    border-radius: 0.33em;
    position: relative;
    margin: 1rem;

    &::before {
      content: '❌';
      position: absolute;
      left: 1rem;
    }
  }

  .warning {
    display: block;
    background: #fffde0;
    color: #c69c0c;
    padding: 1rem 1rem 1rem 2.5rem;
    border-radius: 0.33em;
    position: relative;
    margin: 1rem;

    &::before {
      content: '⚠️';
      position: absolute;
      left: 1rem;
    }
  }
}
