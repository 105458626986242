@import '../variables.scss';

/*----------------------------
    14. INTERACTIVE INPUT
----------------------------*/
.interactive-input {
  width: 100%;
  height: 52px;
  position: relative;

  &.dark {
    input {
      border: none;
      background-color: #f6f6f6;
      color: #000;

      &::-webkit-input-placeholder {
        color: $primary-color;
        opacity: 0.6;
      }

      &::-moz-placeholder {
        color: $primary-color;
        opacity: 0.6;
      }

      &:-ms-input-placeholder {
        color: $primary-color;
        opacity: 0.6;
      }

      &::-ms-input-placeholder {
        color: $primary-color;
        opacity: 0.6;
      }

      &::placeholder {
        color: $primary-color;
        opacity: 0.6;
      }
    }

    .interactive-input-icon-wrap {
      .interactive-input-icon {
        fill: $primary-color;
      }
    }

    .interactive-input-action {
      &:hover {
        .interactive-input-action-icon {
          fill: #fff;
        }
      }

      .interactive-input-action-icon {
        fill: $primary-color;
      }
    }
  }

  &.small {
    height: 48px;
  }

  &.active {
    .interactive-input-action {
      display: -ms-flexbox;
      display: flex;
    }

    .interactive-input-icon {
      display: none;
    }
  }

  input {
    height: 100%;
    padding-right: 60px;
  }

  .interactive-input-icon-wrap {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: center;
    justify-content: center;
    -ms-flex-align: center;
    align-items: center;
    width: 60px;
    height: 100%;
    position: absolute;
    top: 0;
    right: 0;
    pointer-events: none;

    &.actionable {
      pointer-events: all;

      .interactive-input-icon {
        cursor: pointer;

        &:hover {
          fill: #3e3f5e;
          opacity: 1;
        }
      }
    }

    .interactive-input-icon {
      fill: #adafca;
      opacity: 0.6;
      transition:
        fill 0.2s ease-in-out,
        opacity 0.2s ease-in-out;
    }
  }

  .interactive-input-action {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: center;
    justify-content: center;
    -ms-flex-align: center;
    align-items: center;
    width: 60px;
    height: 100%;
    position: absolute;
    top: 0;
    right: 0;
    cursor: pointer;
    display: none;

    &:hover {
      .interactive-input-action-icon {
        fill: #3e3f5e;
        opacity: 1;
      }
    }

    .interactive-input-action-icon {
      fill: #adafca;
      opacity: 0.6;
      pointer-events: none;
      transition:
        fill 0.2s ease-in,
        opacity 0.2s ease-in;
    }
  }
}
