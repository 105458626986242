.tab-switch {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
  justify-content: center;
  width: 240px;
  margin: 0 auto;

  button {
    width: 180px;
    height: 54px;
    border: 1px solid #fff;
    color: #fff;
    font-size: 1rem;
    font-weight: 700;
    line-height: 54px;
    text-align: center;
    cursor: pointer;
    background-color: transparent;
    transition:
      background-color 0.3s ease-in-out,
      color 0.3s ease-in-out;
    border-radius: 0;

    &.active {
      color: #3e3f5e;
      background-color: #fff;
      cursor: auto;
    }

    &:first-child {
      border-top-left-radius: 12px;
      border-bottom-left-radius: 12px;
    }
    &:last-child {
      border-top-right-radius: 12px;
      border-bottom-right-radius: 12px;
    }
  }
}
