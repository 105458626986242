.switch {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  width: 50px;
  height: 21px;
  border: 1px solid #dedeea;
  border-radius: 200px;
  background-color: #eaeaf5;
  cursor: pointer;
  position: relative;
  transition: background-color 0.3s ease-in-out;

  & .switch-button {
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background-color: #fff;
    position: absolute;
    top: 2px;
    left: 2px;
    transition: left 0.3s ease-in-out;
  }

  &.active {
    background-color: #23d2e2;

    & .switch-button {
      left: 30px;
    }
  }
  label {
    margin-left: 60px;
    font-size: 0.875rem;
    color: #adafca;
    font-weight: 600;
    position: absolute;
    width: 100px;
  }
}
