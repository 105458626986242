.linkedinCard {
  display: flex;
  align-items: center;
  width: 100%;
  background-color: #fff;
  border-radius: 10px;
  padding: 30px 32px;
  margin-bottom: 30px;
  position: relative;
  flex-wrap: wrap;
  height: auto;

  .button {
    font-family: 'Quicksand', sans-serif;
    font-size: 1.25rem;
    margin-right: 30px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    height: auto;
    min-height: 45px;
    line-height: 25px;

    @media (max-width: 425px) {
      width: 100%;
      font-size: 0.9rem;
    }

    .icon {
      margin-right: 10px;
    }
  }

  .openModal {
    font-weight: 700;
    cursor: pointer;
  }
}

.title {
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 26px;
}

.questions {
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
}

.attachmentContainer {
  width: 100%;
}

.attachmentSubtitleArea {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: stretch;
  align-content: stretch;
}

.attachmentSubtitleItems {
  width: 60%;
}

.attachmentSubtitle {
  width: 40%;
}

.attachmentFilesError {
  color: red;
  font-size: 13px;
  font-weight: 100;
}

.attachmentButton {
  width: 80%;
  max-width: 200px;
  margin-top: 10px;
}

.attachmentUpload {
  width: 100%;
}
