.error-area {
  text-align: center;

  h1 {
    font-size: 40px;
  }

  p {
    font-size: 20px;
  }

  img {
    width: 100px;
  }
}
