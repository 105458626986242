@import url('https://fonts.googleapis.com/css2?family=Comfortaa:wght@300..700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');

.theme-lugarh {
  color: var(--text-color-primary);
  font-size: 16px;
  font-family: 'Montserrat', sans-serif;
  font-optical-sizing: auto;
  font-style: normal;

  .default-font {
    font-family: 'Montserrat', sans-serif;
    font-optical-sizing: auto;
    font-style: normal;
  }

  .special-font {
    font-family: 'Comfortaa', sans-serif;
    font-optical-sizing: auto;
    font-style: normal;
  }

  .txt-center {
    text-align: center;
  }
}
