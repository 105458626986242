.job-card-wrapper {
  border-radius: 15px;
  background-color: #fff;
  box-shadow: 0 0 40px 0 rgba(94, 92, 154, 0.06);

  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  position: relative;
  min-height: 350px;

  &.add {
    justify-content: space-between !important;
  }

  .job-card-body {
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
    margin-top: 50px;
    margin-bottom: 10px;
  }

  .hexagon {
    width: 30%;
    position: absolute;
    left: 50%;
    transform: translate(-50%, 25%);
    cursor: pointer;

    polygon {
      stroke-width: 8;
      stroke: #fff;
    }
  }

  & .btn-add-new-job {
    color: #adafca;
    box-shadow: none;
    background-color: #eef2f8;

    &:hover {
      background-color: #efa107;
      color: #fff;
    }
  }

  .job-card-content {
    padding: 15px;
    margin-top: 40px;
    min-height: 300px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .title {
      color: #3e3f5e;
      font-weight: 700;
      font-size: 1.125rem;
      margin-top: -30px;
    }

    .location {
      font-size: 0.6875rem;
      color: #adafca;
      font-weight: 700;
      margin-top: 10px;
      text-transform: uppercase;
    }

    & .btn-job-action {
      width: 84px;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;

      p {
        margin: 0;
        font-weight: 700;
        text-transform: uppercase;
        text-align: center;
        margin-top: 10px;
        color: #adafca;
        font-size: 0.6875rem;
      }

      & .green {
        background-color: #00e194;
      }

      & .purple {
        background-color: #7289da;
      }

      & .blue {
        background-color: #1abcff;
      }

      & .custom-color {
        background-color: #ffffff;
        opacity: 0.7;
      }

      button {
        width: 40px;
        height: 40px;
        transition:
          transform 0.2s ease-in-out,
          -webkit-transform 0.2s ease-in-out;
        display: flex;
        justify-content: center;
        align-items: center;

        &:hover {
          transform: translate(0, -4px);
        }

        & svg {
          width: 15px;
          fill: #c1c1c1;
        }
      }
    }
  }

  .job-card-header {
    height: 70px;
    width: 100%;
    background-repeat: no-repeat !important;
    background-size: cover !important;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    position: relative;

    &.add-new-job {
      background: url('../../assets/images/background/grey.png');
    }

    &.active {
      background: #79f2b6;
      opacity: 0.5;
    }

    &.disabled {
      background: #ebf0f8;
      opacity: 1;
    }

    &.custom {
      height: 30px !important;
    }

    .overdue-icon {
      padding: 10px;
      border-radius: 10px;
      background-color: #fff;
      position: absolute;
      right: 20px;
      bottom: -22px;

      -webkit-box-shadow: 5px 9px 26px -8px rgba(0, 0, 0, 0.32);
      -moz-box-shadow: 5px 9px 26px -8px rgba(0, 0, 0, 0.32);
      box-shadow: 5px 9px 26px -8px rgba(0, 0, 0, 0.32);
    }
  }

  .job-card-footer {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    min-height: 65px;
    border-top: 1px solid #eaeaf5;
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
    background-color: #fcfcfd;
    width: 100%;
    padding: 15px;

    .tag-list {
      margin: 0;
    }
  }
}

.user-stats {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
  justify-content: center;

  .user-stat {
    position: relative;
    padding: 0 5px;

    &::after {
      content: '';
      width: 1px;
      height: 20px;
      background-color: #eaeaf5;
      position: absolute;
      top: 6px;
      right: 0;
    }

    &:last-child::after {
      display: none;
    }

    .user-stat-text {
      font-weight: 700;
      text-transform: uppercase;
      text-align: center;
      margin-top: 10px;
      color: #adafca;
      font-size: 0.6875rem;
    }
  }
}
