.candidate-list-wrapper {
  border-radius: 12px;
  box-shadow: 0 0 40px 0 rgba(94, 92, 154, 0.06);
  background-color: #fff;
  min-height: 108px;
  display: flex;
  padding: 0px 40px 0px 40px;
  align-items: center;
  position: relative;

  width: 100%;

  .checkbox {
    position: absolute;
    top: 5px;
    left: 5px;
    z-index: 1;
  }

  .action-text {
    font-weight: 700;
    text-transform: uppercase;
    text-align: center;
    margin-top: 10px;
    color: #797b96;
    font-size: 0.6875rem;
    margin-bottom: 0;
  }

  .demo-box-icon-wrap {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: center;
    justify-content: center;
    -ms-flex-align: center;
    align-items: center;
    width: 72px;
    height: 68px;
    border-radius: 50%;
    background-color: rgba(217, 217, 217, 0.6);
    position: relative;
    z-index: 2;

    margin-right: 20px;

    .demo-box-icon {
      fill: #3e3f5e;
    }
  }

  .candidate-list-info {
    display: flex;
    justify-content: center;
    height: 100%;
    flex-direction: column;
    gap: 10px;

    .candidate-name {
      color: #3e3f5e;
      font-weight: 900;
      font-size: 0.875em;
      line-height: 1em;
      margin: 0;
      font-family: 'Montserrat', sans-serif;
    }

    .date {
      color: #adafca;
      font-size: 0.75em;
      line-height: 1em;
      margin: 0;
      font-family: 'Montserrat', sans-serif;
    }
  }

  button {
    display: flex;
    -ms-flex-pack: center;
    justify-content: center;
    -ms-flex-align: center;
    align-items: center;
    width: 40px;
    height: 40px;
    border: 1px solid #dedeea;
    border-radius: 10px;
    color: #adafca;
    font-size: 0.75rem;
    font-weight: 700;
    cursor: pointer;
    box-shadow: none;
    transition:
      border-color 0.2s ease-in-out,
      background-color 0.2s ease-in-out,
      color 0.2s ease-in-out,
      transform 0.2s ease-in-out;
    background-color: transparent;

    &:hover {
      color: #fff;
      transform: translate(0, -4px);
    }
  }
}

.button-list {
  &.void {
    &.candidate-new {
      background-color: #eef2f8;
      width: 170px;
      height: 44px;
      padding: 12px 10px 12px 10px;
      border-radius: 4px 0px 0px 0px;
      min-width: 130px;
      font-size: 16px;

      &:hover {
        background-color: rgba(193, 193, 193, 0.7);
      }
    }
    &.candidate-new-list {
      background-color: #eef2f8;
      width: 100%;
      height: 60px;
      display: flex;
      gap: 10px;
      align-items: center;
      justify-content: center;

      &:hover {
        background-color: rgba(193, 193, 193, 0.7);
      }
    }
  }
}
