.custom-questions-wrapper {
  .title {
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
    text-transform: uppercase;
  }

  .d-flex-column {
    display: flex;
    flex-direction: column;
  }

  button {
    margin: 0 5px;

    &:first-child {
      margin-left: 0;
    }

    &:last-child {
      margin-right: 0;
    }

    &.none {
      background-color: transparent;
      color: #aeb0ca;
      box-shadow: none;
      border: 1px solid #dedfea;
    }
  }

  .actions {
    display: flex;
    flex-direction: row;
    button {
      width: 40px;
      height: 40px;
      margin-top: 10px;
      margin-bottom: 10px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 2rem;

      svg {
        width: 20px;
      }

      &.danger {
        background: #eb5757;
      }
    }
  }

  .b {
    height: 2px;
    width: 100%;
    margin-bottom: 10px;
    background-color: #dedfea;
  }
}

.unique-response-wrapper {
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 10px;

  .form-input {
    width: 100%;
  }

  .ul {
    width: 15px;
    height: 15px;
    background-color: #3e3f5e;
    border-radius: 50%;
    margin-right: 10px;
  }
}

.radio {
  display: block;
  position: relative;
  cursor: pointer;
  font-size: 22px;
  user-select: none;
  margin-top: 0;
  width: 25px;
  height: 25px;
  margin: 10px;
}

/* Hide the browser's default radio button */
.radio input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

/* Create a custom radio button */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: #fff;
  border: 1px solid #eadee9;
  border-radius: 50%;
}

/* On mouse-over, add a grey background color */
.radio:hover input ~ .checkmark {
  background-color: #ccc;
}

.radio input:checked ~ .checkmark {
  background-color: #00c7d9;
}

.checkmark:after {
  content: '';
  position: absolute;
  display: none;
}

.radio input:checked ~ .checkmark:after {
  display: block;
}

.radio .checkmark:after {
  top: 4px;
  left: 4px;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background: white;
}

.mt-n {
  margin-top: -40px;
}
