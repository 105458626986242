@import './reset';
@import './variables';
@import './theme';
@import './modules/form';
@import './modules/action';
@import './modules/icons';
@import './modules/input';
@import './modules/dropdown';
@import './modules/interactive-input';
@import './modules/user-status';
@import './modules/grid';
@import './modules/popup';
@import './vendor/bootstrap.min.css';
@import './themes/lugarh/main.scss';

.grecaptcha-badge {
  display: none !important;
}

.gap-3 {
  gap: 30px;
}

.spaceUp {
  margin-top: 0px;

  @media screen and (max-width: 425px) {
    margin-top: 12px;
  }
}

.mb-sm-3 {
  margin-bottom: 0px;

  @media screen and (max-width: 425px) {
    margin-bottom: 12px;
    display: flex;
    flex-direction: column;
  }
}

.tox-notifications-container {
  display: none;
}

.bold {
  font-weight: bold;
}
