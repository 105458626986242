$primary-color: #3084d6;
$secondary-color: #235cb2;
$tertiary-color: #6fa4f8;
$background-color: #f9f9f9;
$white-color: #ffffff;
$header-color: $white-color;

$facebook-color: #3c88ef;
$twitter-color: #1abcff;
$linkedin-color: #2e66c2;
$whatsapp-color: #00e194;
$email-button-color: #3e99ee;

$yellow-dark-color: #a0811f;
$yellow-light-color: #fff1c9;

$title-color: #2e325e;
$grey-text: #676b8b;
$text-color: #454545;
$dark-color: #031733;

$line-color: #dadade;
$border-color: #ececec;

$font-size-small: 14px;
$font-size: 16px;
$font-size-med: 18px;
$font-size-lg: 20px;

$heading-1: 2.488rem;
$heading-2: 2.074rem;
$heading-3: 1.728rem;
$heading-4: 1.44rem;
$heading-5: 1.2rem;
$heading-6: 1rem;

$padding-global-left: 55px;
$padding-global-right: 45px;
$font-family: sans-serif;
