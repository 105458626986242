.job-list-wrapper {
  border-radius: 12px;
  background-color: #fff;
  box-shadow: 0 0 40px 0 rgba(94, 92, 154, 0.06);
  overflow: visible;
  min-height: 84px;
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
  align-items: center;

  .col-lg-1-custom {
    max-width: 25px;
  }

  .col-lg-4-custom {
    flex: 0 0 47%;
    max-width: 56%;
  }

  @media (max-width: 540px) {
    .col-lg-1 {
      min-width: 100%;
    }

    .col-lg-1-custom {
      max-width: 200px !important;
    }

    .col-lg-2 {
      min-width: 100%;
    }

    .col-lg-3 {
      min-width: 100%;
    }

    .col-lg-4-custom {
      flex: 0 0 100% !important;
      max-width: 100% !important;
    }
  }

  .hexagon {
    width: 30%;
    position: absolute;
    left: 50%;
    transform: translate(-50%, -20%);
    max-width: 30px;
    max-height: 30px;
    cursor: pointer;

    polygon {
      stroke-width: 8;
      stroke: #fff;
      max-width: 30px;
      max-height: 30px;
    }
  }

  .title {
    color: #3e3f5e;
    font-weight: 700;
    font-size: 0.875rem;
    margin-top: -30px;
  }

  .workspace {
    color: #127eff;
    font-size: 12px;
    font-weight: 600;
    line-height: 1.22;
  }

  .location {
    font-size: 0.75rem;
    color: #adafca;
    font-weight: 700;
    margin-top: 10px;
    text-transform: uppercase;
  }

  & .btn-add-new-job {
    color: #adafca;
    box-shadow: none;
    background-color: #eef2f8;

    &:hover {
      background-color: #efa107;
      color: #fff;
    }
  }

  & .btn-job-action {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    p {
      margin: 0;
      font-weight: 700;
      text-transform: uppercase;
      text-align: center;
      margin-top: 10px;
      color: #797b96;
      font-size: 0.6875rem;
    }

    @media (max-width: 1500px) {
      width: 50px;
      p {
        font-size: 0.5675rem;
      }
    }

    & .green {
      background-color: #00e194;
    }

    & .purple {
      background-color: #7289da;
    }

    & .blue {
      background-color: #1abcff;
    }

    & .custom-color {
      background-color: #ffffff;
      opacity: 1 !important;
    }

    button {
      width: 40px;
      height: 40px;
      transition:
        transform 0.2s ease-in-out,
        -webkit-transform 0.2s ease-in-out;
      display: flex;
      justify-content: center;
      align-items: center;

      &:hover {
        transform: translate(0, -4px);
      }

      & svg {
        width: 15px;
        height: 15px;
        fill: #797b96;
      }
    }
  }

  .job-card-header {
    height: 100%;
    width: 100%;
    min-height: 70px;
    background-repeat: no-repeat !important;
    background-size: cover !important;

    &.add-new-job {
      background: url('../../assets/images/background/grey.png');
    }

    &.active {
      background: #79f2b6;
      opacity: 0.5;
    }

    &.disabled {
      background: #ebf0f8;
      opacity: 1;
    }

    &.custom {
      width: 70% !important;
      max-width: 25px !important;

      &.active {
        background: #79f2b6;
        opacity: 0.5 !important;
      }

      &.disabled {
        background: #ebf0f8;
        opacity: 1 !important;
      }
    }

    @media (max-width: 540px) {
      &.custom {
        min-width: 200% !important;
        min-height: 25px !important;
        flex: 0 0 200% !important;
      }
    }
  }
}

.user-stats {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
  justify-content: space-evenly;

  .user-stat {
    position: relative;
    padding: 0px 0px 0px 0px;

    &.custom {
      margin-left: 10px;
    }

    &::after {
      display: none;
      content: '';
      width: 1px;
      height: 20px;
      background-color: #eaeaf5;
      position: absolute;
      top: 6px;
      right: 0;
    }

    &:last-child::after {
      display: none;
    }

    .user-stat-text {
      font-weight: 700;
      text-transform: uppercase;
      text-align: center;
      margin-top: 10px;
      color: #adafca;
      font-size: 0.6875rem;
    }
  }
}
