/*--------------------
    1. POPUP BOX
--------------------*/
.popup-overlay {
  width: 100%;
  height: 100%;
  background-color: rgba(21, 21, 31, 0.96);
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: -2;
  opacity: 0;
  visibility: hidden;
  transition:
    opacity 0.3s ease-in-out 0s,
    visibility 0.3s ease-in-out 0s;

  &.visible {
    visibility: visible;
    opacity: 1;
    z-index: 100;
  }
}

.popup-box {
  width: 85%;
  max-width: 984px;
  border-radius: 12px;
  background-color: #fff;
  opacity: 0;
  z-index: -1;
  visibility: hidden;
  position: fixed;

  transition:
    transform 0.3s ease-in-out 0s,
    opacity 0.3s ease-in-out 0s,
    visibility 0.3s ease-in-out 0s;

  min-height: 50px;
  max-height: 90vh;

  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  &.visible {
    visibility: visible;
    opacity: 1;
    z-index: 101;
  }

  &-title {
    padding: 32px 28px 0;
    font-size: 1rem;
    font-weight: 700;
    margin: 0;
    margin-left: 4px;
  }

  .modal-content-wrapper {
    overflow-y: auto;
    overflow-x: hidden;
    width: 100%;
    max-height: 80vh;

    &::-webkit-scrollbar {
      width: 6px;
    }

    &::-webkit-scrollbar-track {
      background: #fff;
    }

    &::-webkit-scrollbar-thumb {
      background: #adafca;
      border-radius: 10px;
      border-right: 2px solid #fff;
    }

    &::-webkit-scrollbar-thumb:hover {
      background: rgb(82, 82, 82) fca;
    }
  }
}

// *---------------------------------
//     48. POPUP CLOSE BUTTON
// ---------------------------------*/
.popup-close-button {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-align: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border-radius: 10px;
  background-color: #45437f;
  cursor: pointer;
  position: absolute;
  top: -20px;
  right: -20px;
  z-index: 10;
  transition: background-color 0.2s ease-in-out;
  border: none;

  &:hover {
    background-color: #23d2e2;
  }

  &:focus {
    outline: 0;
  }

  .popup-close-button-icon {
    pointer-events: none;
  }
}

.icon-cross {
  fill: #fff;
  width: 12px;
  height: 12px;
}

/*--------------------
    MEDIA QUERIES 
--------------------*/
@media screen and (max-width: 680px) {
  .popup-box .popup-box-body {
    display: block;
  }
  .popup-box .popup-box-body .popup-box-sidebar,
  .popup-box .popup-box-body .popup-box-content {
    width: 100%;
  }
  .popup-box .popup-box-body .popup-box-sidebar {
    border-right: none;
  }
}
