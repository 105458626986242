.candidate-filter {
  width: 500px;
  background-color: #fff;
  box-shadow: 0 0 40px 0 rgba(94, 92, 154, 0.06);
  transition:
    transform 0.4s ease-in-out,
    -webkit-transform 0.4s ease-in-out;

  position: fixed;
  right: 0;
  top: 80px;
  // z-index: 98;
  overflow: auto;
  height: calc(100% - 80px);
  transform: translateX(500px);

  &-button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 450px;
    height: 50px;
    padding: 24px;
    border-radius: 4px;
    background-color: #2f5eac;
    margin: 0 20px 0 25px;
    &-text {
      color: #fff;
      font-size: 1rem;
      font-weight: 700;
    }
  }

  &-section-title {
    color: #3e3f5e;
    font-weight: 700;
    font-size: 20px;
  }

  &-container {
    padding: 11px 20px;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    flex: 1;
    height: calc(100% - 80px);
    margin-left: 100px;
    transition:
      all 0.4s ease-in-out,
      -webkit-transform 0.4s ease-in-out;
  }

  &.filter-active {
    transform: translateX(0px);
    z-index: 10;

    .candidate-filter-container {
      margin-left: 0px;
    }
  }

  @media screen and (max-width: 680px) {
    display: none;
  }
}
