.theme-lugarh {
  .MuiFormControl-root {
    margin: 0;
    max-width: 525px;
  }

  .MuiInputLabel-root,
  label.MuiInputLabel-root.Mui-focused {
    color: #adafca;
    font-size: 0.85rem;
    font-weight: 600;
    line-height: 1.2rem;
    font-family: 'Quicksand', sans-serif;
  }

  .MuiInputLabel-root.MuiInputLabel-shrink {
    transform: translate(14px, -9px) scale(1);
  }

  .MuiOutlinedInput-root {
    height: 48px;
    border-radius: 5px;
  }

  .MuiOutlinedInput-notchedOutline {
    border-color: #dedeea !important;

    legend {
      height: 14px;
      font-size: 0.9em;
    }
  }

  .Mui-focused .MuiOutlinedInput-notchedOutline {
    border: 1px solid #615dfa !important;
  }

  .MuiOutlinedInput-root:hover fieldset.MuiOutlinedInput-notchedOutline {
    border-color: #dedeea;
  }
}
