.modal-add-document {
  .popup-box-title {
    font-size: 24px;
    color: #1b499b;
    text-align: center;
  }
  .modal-add-document-content {
    padding: 32px;
    display: flex;
    flex-direction: column;
    .info-content {
      margin-bottom: 20px;
    }
    .alert-content {
      display: flex;
      align-self: flex-start;
      align-items: center;
      justify-content: center;
      margin-top: 20px;
      span {
        text-align: left;
        color: #1b499b;
        font-size: 12px;
      }
    }
  }
}
