.timeline-wrapper {
  ul {
    li {
      position: relative;
      padding-left: 20px;

      p {
        &.timeline-title {
          font-size: 0.875rem;
          font-weight: 700;
          line-height: 1em;
        }

        &.timeline-subtitle {
          margin-top: 6px !important;
          color: #adafca;
          font-size: 0.75rem;
          font-weight: 700;
          text-transform: uppercase;
          line-height: 1em;
        }

        &.timeline-content {
          margin-top: 8px !important;
          font-size: 0.875rem;
          line-height: 1.7142857143em;
          font-weight: 500;
          padding-bottom: 20px;
        }
      }

      &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 10px;
        height: 10px;
        border-radius: 50%;
        border: 3px solid #23d2e2;
        background-color: #eaeaf5;
      }

      &:not(:last-child)::before {
        content: '';
        position: absolute;
        top: 10px;
        left: 5px;
        width: 1px;
        background-color: #eaeaf5;
        height: 100%;
      }
    }
  }
}
