.header.header-content {
  min-height: 60px;
  height: auto;
  justify-content: space-between;

  @media (max-width: 624px) {
    justify-content: center;
    flex-wrap: wrap;
    padding: 10px 0;
  }

  .header-actions.logo {
    display: flex;
  }

  .header-actions.list {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    overflow: auto;
    overflow-y: hidden;

    .action-list.dark {
      height: auto;
      @media (max-width: 624px) {
        min-width: 600px;
        margin: 5px 0;
      }
    }
  }
}
