.job-header-wrapper {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -ms-flex-align: center;
  align-items: center;
  min-height: 96px;
  padding: 0 28px;
  border-radius: 12px;
  box-shadow: 0 0 40px 0 rgba(94, 92, 154, 0.06);
  background-color: #fff;

  @media (max-width: 1200px) {
    padding: 0;
    flex-direction: column;
    padding: 30px;
  }

  @media (max-width: 540px) {
    flex-direction: inherit;
  }

  .input-with-button {
    position: relative;
    width: 100%;
    margin-right: 30px;

    @media (max-width: 1200px) {
      margin: 0;
    }

    @media (max-width: 425px) {
      margin-bottom: 16px;
    }
  }

  .vacancy-filter-button {
    width: 64px;
    position: absolute;
    right: 0;
    margin-top: -48px;

    svg {
      fill: #fff;
      width: 20px;
      height: 20px;
    }
  }

  .filter-select {
    display: none;
    width: 100%;
    margin: 10px 0;

    @media (max-width: 1200px) {
      display: block;
    }
  }

  .filter-tabs {
    display: -ms-flexbox;
    display: flex;
    height: 96px;
    justify-content: flex-end;

    @media (max-width: 1200px) {
      display: none;
    }

    .filter-tab {
      display: -ms-flexbox;
      display: flex;
      -ms-flex-pack: center;
      justify-content: center;
      -ms-flex-align: center;
      align-items: center;
      width: 162px;
      height: 100%;
      border-bottom: 4px solid transparent;
      cursor: pointer;
      transition: border-color 0.2s ease-in-out;
      position: relative;
      font-weight: 700;

      p {
        color: #adafca;
        font-size: 0.875rem;
      }

      &::before {
        content: '';
        width: 1px;
        height: 20px;
        background-color: #eaeaf5;
        position: absolute;
        top: 38px;
        left: 0;
        flex: 0 0 100px;
      }

      &:last-child::after {
        content: '';
        width: 1px;
        height: 20px;
        background-color: #eaeaf5;
        position: absolute;
        top: 38px;
        right: 0;
        margin-right: 10px;
      }

      &.active {
        border-bottom-color: #c1c1c1;

        p {
          color: #3e3f5e;
        }
      }
    }
  }

  .view-type {
    display: flex;
    flex-direction: row;
    justify-content: center;

    svg {
      width: 20px;
      margin: 0 10px;
      cursor: pointer;
      fill: #adafca;
      opacity: 0.4;
      transition:
        opacity 0.2s ease-in-out,
        fill 0.2s ease-in-out;

      &:hover,
      &.active {
        fill: #3e3f5e;
        opacity: 1;
      }
    }
  }
}

.input-with-button {
  .MuiFormControl-root {
    max-width: 370px;
  }
}
