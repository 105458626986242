@media only screen and (max-width: 319px) {
  .messageCookie {
    justify-content: center;
    text-align: left;
    margin: 0px 20px 0px 20px;
  }

  .CookieConsent {
    align-items: center !important;
    align-content: center !important;
  }

  #rcc-decline-button {
    width: 80px !important;
  }

  #rcc-confirm-button {
    width: 80px !important;
  }
}

@media only screen and (min-width: 320px) {
  .messageCookie {
    justify-content: center;
    text-align: left;
  }

  .CookieConsent {
    align-items: center !important;
    align-content: center !important;
  }

  #rcc-decline-button {
    width: 120px !important;
  }

  #rcc-confirm-button {
    width: 120px !important;
  }
}

@media only screen and (min-width: 768px) {
  .messageCookie {
    justify-content: center;
    text-align: left;
  }

  .CookieConsent {
    align-items: center !important;
    align-content: center !important;
  }
}
