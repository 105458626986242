.theme-lugarh {
  .single-selectable-list {
    div {
      &.single-selectable-list__item-button {
        &.MuiButtonBase-root {
          height: inherit;
          margin: -20px;

          &.Mui-selected {
            background-color: #e4fbe7;

            &:hover {
              background-color: #e4fbe7;
            }

            .single-selectable-list__item-title {
              color: #00bf12;
            }

            .single-selectable-list__item-subtitle {
              color: #00bf12;
            }

            svg {
              margin-left: 12px;
            }
          }
        }

        p {
          margin: 0;
        }
      }
    }

    &__item-title {
      font-size: 16px;
      font-weight: 500;
      line-height: 22.4px;
      margin-bottom: 4px;
    }

    &__not-found {
      padding: 20px;
    }

    .single-selectable-list__item-subtitle {
      font-size: 14px;
      font-weight: 500;
      line-height: 19.6px;
      color: #141314;
      opacity: 0.6;
    }

    .form__field--search {
      margin-bottom: 21px;
      padding: 0px 16px;
      svg {
        left: 34px;
      }
    }

    .form__list-item {
      margin: 0 -20px 0 -20px;
      width: initial;
      padding: 6px 20px;
    }

    .form__list-item-single {
      margin: 0;
      width: initial;
      background-color: rgba(185, 160, 185, 0.062745098);
      .form__list-title {
        padding: 6px 20px;
      }
    }

    .MuiList-root {
      padding-top: 0;
      padding-bottom: 0;
    }

    .MuiButtonBase-root {
      margin: 0 -18px;
      padding: 6px 20px;
    }
    .MuiListItemIcon-root {
      order: 2;
      min-width: initial;

      .MuiSvgIcon-root {
        font-size: 1rem;
      }
    }
  }
}
