.share-item {
  display: flex;
  border-radius: 10px;
  padding: 10px 15px;
  margin-bottom: 16px;

  .icon {
    height: 60px;
    display: flex;
    align-items: center;

    img {
      max-height: 31px;
      max-width: 31px;
    }
  }

  .content {
    margin-left: 15px;
    display: flex;
    width: 100%;
    flex-direction: column;
  }

  a,
  p,
  strong {
    margin: 2px;
    color: #fff;
  }

  a:hover {
    color: #fff !important;
  }

  strong {
    font-size: 14px;
  }

  p {
    font-weight: 600;
    font-size: 13px;
  }
}

a.share-job-link {
  cursor: pointer;
  text-decoration: underline;
}
