// IMPORTANT: colors variables are managed by the ThemeController at app/src/context/ThemeContex.tsx.
// NOTE: Please do not modify this file directly as it will be overwritten.
// NOTE: Do not use the !important directive in this file.

.theme-lugarh {
  --primary-color: #950099;
  --primary-color-dark: #5a005d;
  --primary-color-light: #cb00d2;
  --secondary-color: #127eff;
  --secondary-color-dark: #07448f;
  --secondary-color-light: #1298ff;
  --tertiary-color: #6ac425;
  --tertiary-color-dark: #539b1d;
  --tertiary-color-light: #6dc42f;
  --background-color-light: #ffffff;
  --text-primary-color: #141314;
  --mid-grey-color: #8e8e8e;
}
