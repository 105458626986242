.sidebar-menu-link {
  margin: 0;
  display: block;
  height: 34px;
  font-size: 0.875rem;
  font-weight: 700;
  line-height: 34px;
  cursor: pointer;
  transition:
    transform 0.2s ease-in-out 0s,
    color 0.25s ease-in-out 0s,
    -webkit-transform 0.2s ease-in-out 0s;

  &:hover,
  &.active {
    color: #c1c1c1;
    transform: translate(4px, 0px);
  }
}
