.theme-lugarh {
  .form {
    &__container {
      padding: 45px 55px;

      &--box {
        background-color: white;
        border-radius: 20px;
        box-shadow: 0 0 28px 8px #a8a8a826;
      }
    }

    &__title {
      font-size: 24px;
      font-weight: 600;
      margin: 0;
      color: #010042;
    }

    &__row {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 20px;

      .form__field {
        margin-top: 0;
      }
    }

    &__field {
      margin-top: 20px;
      display: flex;
      flex-wrap: wrap;

      &--password {
        position: relative;

        input {
          padding: 0 62px 0 18px;
          @extend .default-font;
        }
      }

      &--search {
        position: relative;

        svg {
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          left: 15px;
        }

        input {
          padding-left: 44px;
        }
      }

      input {
        @extend .default-font;
        font-weight: 500;
      }
    }

    &__label {
      font-weight: 600;
      @extend .default-font;

      &--checkbox {
        margin-left: 10px;
        font-size: 14px;
      }
    }

    &__input {
      &--error {
        border-color: #ff0000;

        &:focus {
          border-color: #ff0000;
        }
      }
    }

    &__error {
      color: #ff0000;
      font-size: 14px;
      margin: 10px 0 0 0;
      font-weight: 600;

      &--btn-top {
        margin: 0 0 10px 0;
      }
    }

    &__text {
      font-size: 14px;
      margin-top: 20px;
      opacity: 0.6;
    }

    &__link {
      text-decoration: underline;
      font-size: 14px;
      opacity: 0.6;
      font-weight: 600;
      @extend .default-font;

      &:hover {
        color: var(--primary-color);
      }

      &--primary {
        color: var(--primary-color);
        opacity: 1;
        font-weight: 700;
      }
    }

    &__button {
      margin-top: 20px;
    }

    &__list-item {
      background-color: #b9a0b910;
    }
  }
}
