label,
input[type='text'],
input[type='email'],
input[type='number'],
input[type='password'],
textarea,
select,
button {
  font-family: 'Quicksand', sans-serif;
}

label {
  display: block;
  margin: 0;
  color: #3e3f5e;
  line-height: 1em;
}

a {
  transition: all 0.2s ease-in-out;
  text-decoration: none;
}

p {
  margin-block-start: 1em;
  margin-block-end: 1em;
}

input[type='text'],
input[type='email'],
input[type='number'],
input[type='password'],
textarea,
select,
button {
  width: 100%;
  border-radius: 5px;
  font-size: 1rem;
  font-weight: 700;
}

input[type='text']:not(.MuiInputBase-input),
input[type='email']:not(.MuiInputBase-input),
input[type='number']:not(.MuiInputBase-input),
input[type='password']:not(.MuiInputBase-input),
textarea,
select {
  background-color: #fff;
  border: 1px solid #dedeea;
  color: #3e3f5e;
  transition: border-color 0.2s ease-in-out;
}

input[type='text']:focus,
input[type='email']:focus,
input[type='number']:focus,
input[type='password']:focus,
textarea:focus,
select:focus {
  border-color: #615dfa;
}

input[type='text'],
input[type='email'],
input[type='number'],
input[type='password'] {
  height: 54px;
  padding: 0 18px;
}

input[type='text']::-webkit-input-placeholder,
input[type='email']::-webkit-input-placeholder,
input[type='number']::-webkit-input-placeholder,
input[type='password']::-webkit-input-placeholder {
  color: #adafca;
  font-size: 0.875rem;
  font-weight: 500;
}

input[type='text']::-moz-placeholder,
input[type='email']::-moz-placeholder,
input[type='number']::-moz-placeholder,
input[type='password']::-moz-placeholder {
  color: #adafca;
  font-size: 0.875rem;
  font-weight: 500;
}

input[type='text']:-ms-input-placeholder,
input[type='email']::-ms-input-placeholder,
input[type='number']::-ms-input-placeholder,
input[type='password']:-ms-input-placeholder {
  color: #adafca;
  font-size: 0.875rem;
  font-weight: 500;
}

input[type='text']::-ms-input-placeholder,
input[type='email']::-ms-input-placeholder,
input[type='number']::-ms-input-placeholder,
input[type='password']::-ms-input-placeholder {
  color: #adafca;
  font-size: 0.875rem;
  font-weight: 500;
}

input[type='text']::placeholder,
input[type='email']::placeholder,
input[type='number']::placeholder,
input[type='password']::placeholder {
  color: #adafca;
  font-size: 0.875rem;
  font-weight: 500;
}

textarea {
  padding: 14px 18px;
  resize: none;
}

textarea::-webkit-input-placeholder {
  color: #adafca;
  font-weight: 600;
}

textarea::-moz-placeholder {
  color: #adafca;
  font-weight: 600;
}

textarea:-ms-input-placeholder {
  color: #adafca;
  font-weight: 600;
}

textarea::-ms-input-placeholder {
  color: #adafca;
  font-weight: 600;
}

textarea::placeholder {
  color: #adafca;
  font-weight: 600;
}
