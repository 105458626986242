.emailButton {
  background: #d1d1d1;
  bottom: 15px;
  left: -74px;
  position: fixed;
  z-index: 9999;
  width: 108px;
  height: 44px;
  border-radius: 25px;
  margin: 0px 0px 0px 95px;
  cursor: pointer;
}

.emailButtonIconArea {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  align-content: center;
  margin-right: 8px;
}

.emailButtonIconArea > span {
  font-size: 15px;
  color: white;
}

.emailButtonIcon {
  transform: scale(0.7);
}
