@import '../../assets/styles/variables.scss';

/*------------------------------
    5. FORM INPUT DECORATED
------------------------------*/
.form-input-decorated {
  position: relative;

  input[type='text'] {
    padding-right: 60px;
  }

  .form-input-icon {
    fill: #adafca;
    opacity: 0.6;
    pointer-events: none;
    position: absolute;
    top: 14px;
    right: 20px;
  }
}

/*-------------------
    6. FORM INPUT
-------------------*/
.form-input {
  position: relative;
  width: 100%;

  input:disabled,
  textarea:disabled {
    background: $background-color;
  }

  label {
    color: #adafca;
    font-size: 1rem;
    font-weight: 600;
    position: absolute;
    top: 20px;
    left: 20px;
    transition: all 0.3s ease-in-out;
    pointer-events: none;
  }

  &.social-input .social-link {
    position: absolute;
    top: 4px;
    left: 4px;
    pointer-events: none;
  }

  &.social-input input {
    padding-left: 64px;
  }

  &.social-input label {
    left: 64px;
  }

  &.social-input.active label {
    left: 58px;
  }

  &.currency-decorated::before {
    content: '$';
    color: $primary-color;
    font-size: 0.875rem;
    font-weight: 700;
    position: absolute;
    top: 12px;
    left: 22px;
    pointer-events: none;
  }

  &.currency-decorated input {
    padding-left: 32px;
  }

  &.small {
    label,
    input,
    textarea {
      font-size: 0.875rem;
    }

    label {
      top: 16px;
    }

    input {
      height: 48px;
    }

    textarea {
      height: 100px;
    }

    button {
      width: 64px;
      position: absolute;
      top: 0;
      right: 0;
    }
  }

  &.full {
    height: 100%;
    textarea {
      height: 100%;
    }
  }

  &.mid-textarea textarea {
    height: 124px;
  }

  &.medium-textarea textarea {
    height: 160px;
  }

  &.active label {
    background-color: #fff;
    padding: 0 6px;
    font-size: 0.75rem;
    top: -6px;
    left: 12px;
  }

  &.with-fixed-value {
    input {
      padding-right: 90px;
    }
  }

  .fixed-value {
    position: absolute;
    top: 0;
    right: 0;
    height: 48px;
    margin: 0;
    width: auto;
    padding: 0 10px;
    display: flex;
    align-items: center;
    color: white;
    background-color: #00c7d9;
    font-size: 14px;
    font-weight: bold;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
  }
}

.form-input.dark {
  input[type='text'],
  input[type='password'],
  textarea {
    border: none;
    background-color: $background-color;
    color: #fff;
  }

  input[type='text']::-webkit-input-placeholder,
  input[type='password']::-webkit-input-placeholder,
  textarea::-webkit-input-placeholder {
    color: $primary-color;
    opacity: 0.6;
  }

  input[type='text']::-moz-placeholder,
  input[type='password']::-moz-placeholder,
  textarea::-moz-placeholder {
    color: $primary-color;
    opacity: 0.6;
  }

  input[type='text']:-ms-input-placeholder,
  input[type='password']:-ms-input-placeholder,
  textarea:-ms-input-placeholder {
    color: $primary-color;
    opacity: 0.6;
  }

  input[type='text']::-ms-input-placeholder,
  input[type='password']::-ms-input-placeholder,
  textarea::-ms-input-placeholder {
    color: $primary-color;
    opacity: 0.6;
  }

  input[type='text']::placeholder,
  input[type='password']::placeholder,
  textarea::placeholder {
    color: $primary-color;
    opacity: 0.6;
  }
}

/*-------------------
    HIDE NUMBER INPUT ARROWS
-------------------*/

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}
