/*----------------------
    1. CONTENT GRID
----------------------*/
.content-grid {
  max-width: 1184px;
  padding: 114px 0 100px 80px;
  margin: 0 auto;

  &.hidden {
    display: None;
  }

  &.full {
    max-width: 100%;
    padding: 80px 80px 0;
  }

  &.medium {
    max-width: 784px;
    padding: 0 0 100px;
  }

  .section-navigation {
    margin-top: 16px;

    & + .grid {
      margin-top: 16px;
    }
  }

  .table {
    margin-top: 16px;

    & + .loader-bars {
      margin-top: 52px;
    }
  }

  .section-filters-bar {
    margin-top: 30px;
  }

  .grid {
    margin-top: 32px;

    &.medium-space {
      margin-top: 64px;
    }

    &.small-space {
      margin-top: 16px;
    }

    .grid {
      margin-top: 0;
    }
  }

  .photos-masonry,
  .section-slider {
    margin-top: 32px;
  }

  .section,
  .section-header {
    margin-top: 60px;
  }

  .section-banner + .section-filters-bar {
    margin-top: 64px;
  }

  .calendar-widget {
    margin-top: 16px;
  }

  .forum-content {
    .quick-post {
      margin-top: 60px;
    }
  }

  .forum-sidebar {
    padding-top: 40px;
  }

  .account-hub-content {
    .section-header {
      margin-bottom: 28px;
    }

    .section-header:first-child {
      margin-top: 0;
    }
  }

  .notification-box-list + .loader-bars {
    margin-top: 60px;
  }
}

/*--------------
    2. GRID
--------------*/
.grid {
  display: grid;
  grid-template-columns: 100%;
  grid-gap: 16px;
  -ms-flex-align: start;
  align-items: start;

  &.centered {
    -ms-flex-pack: center;
    justify-content: center;
  }

  &.stretched {
    -ms-flex-align: stretch;
    align-items: stretch;
  }

  &.top-space {
    margin-top: 64px;
  }

  &.grid-3-6-3 {
    grid-template-areas: 'sidebar1 content sidebar2';
    grid-template-columns: 23.9864864865% 49.3243243243% 23.9864864865%;

    & > .grid-column:nth-child(1) {
      grid-area: sidebar1;
    }

    & > .grid-column:nth-child(2) {
      grid-area: content;
    }

    & > .grid-column:nth-child(3) {
      grid-area: sidebar2;
    }
  }

  &.grid-6-3_9 {
    grid-template-areas: 'content sidebar';
    grid-template-columns: 66.0633484163% 32.1266968326%;

    & > .grid-column:nth-child(1) {
      grid-area: content;
    }

    & > .grid-column:nth-child(2) {
      grid-area: sidebar;
    }
  }

  &.grid-3-6_9 {
    grid-template-areas: 'sidebar content';
    grid-template-columns: 32.1266968326% 66.0633484163%;

    & > .grid-column:nth-child(1) {
      grid-area: sidebar;
    }

    & > .grid-column:nth-child(2) {
      grid-area: content;
    }
  }

  &.grid-3-9 {
    grid-template-areas: 'sidebar content';
    grid-template-columns: 23.9864864865% 74.6621621622%;

    & > .grid-column:nth-child(1) {
      grid-area: sidebar;
    }

    & > .grid-column:nth-child(2) {
      grid-area: content;
    }
  }

  &.grid-9-3 {
    grid-template-areas: 'content sidebar';
    grid-template-columns: 74.6621621622% 23.9864864865%;

    & > .grid-column:nth-child(1) {
      grid-area: content;
    }

    & > .grid-column:nth-child(2) {
      grid-area: sidebar;
    }
  }

  &.grid-8-4 {
    grid-template-areas: 'content sidebar';
    grid-template-columns: 66.2162162162% 32.4324324324%;

    & > .grid-column:nth-child(1) {
      grid-area: content;
    }

    & > .grid-column:nth-child(2) {
      grid-area: sidebar;
    }
  }

  &.grid-6-3 {
    grid-template-areas: 'content sidebar';
    grid-template-columns: 49.3243243243% 23.9864864865%;

    & > .grid-column:nth-child(1) {
      grid-area: content;
    }

    & > .grid-column:nth-child(2) {
      grid-area: sidebar;
    }
  }

  &.grid-half {
    grid-template-columns: 1fr 1fr;
  }

  &.grid-4-4-4 {
    grid-template-columns: repeat(auto-fit, 384px);
  }

  &.grid-4-4 {
    grid-template-columns: repeat(auto-fit, 384px);
  }

  &.grid-3-3-3-3 {
    grid-template-columns: repeat(auto-fit, 284px);
  }

  &.grid-3-3-3 {
    grid-template-columns: repeat(auto-fit, 284px);
  }

  &.grid-2-2-2-2-2-2 {
    grid-template-columns: repeat(auto-fit, 177px);
  }

  &.grid-layout-1 {
    grid-template-columns: repeat(4, 23.9864864865%);
    grid-template-areas:
      'sidebar header header header'
      'sidebar content content contentsidebar';

    .grid-sidebar {
      grid-area: sidebar;
    }

    .grid-header {
      grid-area: header;
    }

    .grid-content {
      grid-area: content;
    }

    .grid-content-sidebar {
      grid-area: contentsidebar;
    }
  }

  .grid-column {
    display: grid;
    grid-template-columns: 100%;
    grid-gap: 16px;
    min-width: 0;

    .loader-bars {
      margin: 48px auto 0;
    }

    .simple-tab-items {
      margin: 16px 0;
    }
  }
}

/*--------------------
    MEDIA QUERIES
--------------------*/
@media screen and (max-width: 1500px) {
  .content-grid {
    max-width: 1142px;
  }

  .content-grid-expanded-menu {
    max-width: 1040px;
    margin-right: 68px;
  }
}

@media screen and (max-width: 1366px) {
  .content-grid {
    max-width: 100%;
    padding: 112px 50px 200px 112px;
    margin: 0 auto;
  }
  .content-grid.full {
    padding: 80px 80px 0;
  }
  .content-grid.medium {
    max-width: 100%;
    padding: 0 112px 200px;
  }
}

@media screen and (max-width: 1365px) {
  .content-grid .account-hub-content .section-header:first-child {
    margin-top: 60px;
  }
  .grid.grid-3-6-3 {
    grid-template-columns: 32.4324324324% 66.2162162162%;
    grid-template-areas:
      'sidebar1 content'
      'sidebar2 content';
  }
  .grid.grid-3-9,
  .grid.grid-9-3,
  .grid.grid-8-4 {
    grid-template-columns: 100%;
    grid-template-areas:
      'content'
      'sidebar';
  }
  .grid.grid-3-9 .forum-sidebar,
  .grid.grid-9-3 .forum-sidebar,
  .grid.grid-8-4 .forum-sidebar {
    display: none;
  }
  .grid.grid-layout-1 {
    grid-template-areas:
      'header header header header'
      'sidebar sidebar contentsidebar contentsidebar'
      'content content content content';
  }
  .grid.grid-half.change-on-desktop {
    grid-template-columns: 100%;
  }
  .grid.centered-on-mobile {
    -ms-flex-pack: center;
    justify-content: center;
  }
  .photos-masonry {
    grid-template-areas: unset;
    grid-template-columns: repeat(auto-fit, 184px);
    grid-template-rows: none;
    -ms-flex-pack: center;
    justify-content: center;
  }
  .photos-masonry .photo-preview {
    height: 184px;
  }
  .photos-masonry .photo-preview:nth-child(1),
  .photos-masonry .photo-preview:nth-child(2),
  .photos-masonry .photo-preview:nth-child(3),
  .photos-masonry .photo-preview:nth-child(4),
  .photos-masonry .photo-preview:nth-child(5),
  .photos-masonry .photo-preview:nth-child(6),
  .photos-masonry .photo-preview:nth-child(7),
  .photos-masonry .photo-preview:nth-child(8),
  .photos-masonry .photo-preview:nth-child(9),
  .photos-masonry .photo-preview:nth-child(10) {
    grid-area: auto;
  }
}

@media screen and (max-width: 1070px) {
  .grid.grid-3-6-3 {
    grid-template-columns: 100%;
    grid-template-areas:
      'sidebar1'
      'sidebar2'
      'content';
  }
  .grid.grid-3-6-3.mobile-prefer-content {
    grid-template-areas:
      'content'
      'sidebar1'
      'sidebar2';
  }
  .grid.grid-3-6-3.mobile-prefer-content .grid-column:nth-child(1),
  .grid.grid-3-6-3.mobile-prefer-content .grid-column:nth-child(3) {
    display: none;
  }
  .grid.grid-3-9,
  .grid.grid-6-3_9,
  .grid.grid-3-6_9 {
    grid-template-columns: 100%;
    grid-template-areas:
      'content'
      'sidebar';
  }
}

@media screen and (max-width: 960px) {
  .grid.grid-half {
    grid-template-columns: 100%;
  }
  .grid.grid-layout-1 {
    grid-template-columns: 100%;
    grid-template-areas:
      'header'
      'contentsidebar'
      'sidebar'
      'content';
  }
}

@media screen and (max-width: 680px) {
  .content-grid {
    width: 95%;
    padding: 92px 0 200px;

    &.full {
      width: 100%;
      padding: 60px 0 0;
    }

    &.medium {
      width: 95%;
      padding: 0 0 200px;
    }
  }

  .chat-widget,
  .navigation-widget-desktop {
    display: none;
  }
  .chat-widget-wrap .chat-widget {
    display: block;
  }
}

@media screen and (max-width: 460px) {
  .grid.grid-4-4-4,
  .grid.grid-4-4,
  .grid.grid-3-3-3-3 {
    grid-template-columns: 100%;
  }
}
