.plans-card {
  width: 1150px;
  padding: 55px;
  border-radius: 12px;
  background-color: #fff;
  box-shadow: 0 0 60px 0 rgba(94, 92, 154, 0.12);

  &.register {
    margin-top: 80px;
  }

  @media screen and (max-width: 1365px) {
    display: none;

    &.register {
      margin-top: 0;
    }
  }

  @media screen and (max-width: 500px) {
    padding: 40px 32px;
  }

  img {
    position: absolute;
    bottom: 525px;
    right: 900px;
  }

  @media screen and (max-width: 991px) {
    transform: none !important;
    transition: none !important;
  }

  &.active {
    transform: translate(0, 0);
    opacity: 1;
    visibility: visible;
    z-index: 2;
    transition: all 0.6s ease-in-out 0s;
    display: block;
  }

  &.hidden {
    transform: translate(50%, 0);
    opacity: 0;
    visibility: hidden;
    position: absolute;
    z-index: 1;
    transition: all 0.6s ease-in-out 0s;
    display: block;
  }

  h2 {
    text-align: center;
    font-size: 1.625rem;
  }

  p {
    text-align: center;
  }

  .form {
    margin-top: 76px;

    .form-input {
      margin: 20px 0;
    }

    a {
      color: #adafca;
      font-size: 0.875rem;
      font-weight: 700;
      line-height: 22px;
    }

    .recaptcha {
      display: flex;
      justify-content: center;
      margin-top: 15px;
    }

    button {
      margin: 20px 0;
    }

    .form-row {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      padding: 0 10px;
    }
  }

  .lined-text {
    font-size: 0.875rem;
    font-weight: 700;
    text-align: center;
    position: relative;

    &::before,
    &::after {
      content: '';
      width: 70px;
      height: 1px;
      background-color: #eaeaf5;
      position: absolute;
      top: 7px;
    }

    &::after {
      right: 0;
    }
  }

  .user-stats {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: center;
    justify-content: center;

    .user-stat {
      position: relative;
      padding: 0 15px;

      &::after {
        content: '';
        width: 1px;
        height: 20px;
        background-color: #eaeaf5;
        position: absolute;
        top: 6px;
        right: 0;
      }

      &:last-child::after {
        display: none;
      }

      .user-stat-text {
        font-weight: 700;
        text-transform: uppercase;
        text-align: center;
        margin-top: 10px;
        color: #adafca;
        font-size: 0.6875rem;
      }
    }
  }
}

.language-button {
  width: 35px;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  background: transparent;
  margin: 10px;

  img {
    border-radius: 10px;
  }

  &.active {
    width: 45px;
    height: 45px;
  }
}

.plans-card-logout-button {
  width: auto;
  text-align: right;
  margin: 5px 15px 0 0;
  font-weight: bold;
  cursor: pointer;
}

// RESPONSIVE
@media screen and (max-width: 1080px) {
  .card-plan-area {
    flex-wrap: wrap;
  }
}
