/*-------------------------
    1. USER STATUS LIST
-------------------------*/
.user-status-list {
  .user-status {
    margin-bottom: 22px;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

/*---------------------
    2. USER STATUS
---------------------*/
.user-status {
  min-height: 44px;
  padding: 2px 0 0 52px;
  position: relative;

  &.no-padding-top {
    padding-top: 0;
  }

  &.notification {
    padding-right: 60px;
  }

  &.request {
    padding-right: 100px;

    &-small {
      padding-right: 48px;

      .user-status-title {
        margin-top: 2px;
        line-height: 1.1428571429em;
      }

      .user-status-text {
        margin-top: 8px;
      }
    }

    .user-status-title {
      margin-top: 2px;
      line-height: 1.1428571429em;
    }

    .user-status-text {
      margin-top: 8px;
    }
  }

  .user-status-avatar,
  .user-status-activity {
    position: absolute;
    top: 0;
    left: 0;
  }

  .user-status-activity {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: center;
    justify-content: center;
    -ms-flex-align: center;
    align-items: center;
    width: 44px;
    height: 44px;
    border-radius: 50%;
  }

  .user-status-activity {
    &.activity-reaction {
      background-color: #615dfa;
    }

    &.activity-comment {
      background-color: #4f8dff;
    }

    &.activity-share {
      background-color: #3ad2fe;
    }

    &.activity-update {
      background-color: #23d2e2;
    }

    .user-status-activity-icon {
      fill: #fff;
    }
  }

  .user-status-title.medium + .user-status-text {
    margin-top: 2px;
  }

  .user-status-title {
    color: #3e3f5e;
    font-size: 0.875rem;
    font-weight: 500;
    line-height: 1.4285714286em;
    margin: 0;

    &.medium {
      font-size: 1rem;
    }

    .bold {
      color: #3e3f5e;
      font-weight: 700;
    }

    .highlighted {
      color: #00c7d9;
      font-weight: 600;
    }
  }

  .user-status-timestamp {
    margin-top: 10px;
    color: #8f91ac;
    font-size: 0.75rem;
    font-weight: 500;

    &.small-space {
      margin-top: 4px;
    }

    &.floaty {
      margin-top: 0;
      position: absolute;
      top: 7px;
      right: 0;
    }
  }

  .user-status-workspace {
    color: #127eff;
    font-size: 12px;
    line-height: 1.25;
  }

  .user-status-text {
    margin-top: 4px;
    color: #3e3f5e;
    font-size: 0.875rem;
    font-weight: 300;
    font-size: 12px;

    &.small {
      font-size: 0.75rem;

      &-space {
        margin-top: 4px;
      }
    }

    a {
      color: #3e3f5e;
    }
  }

  .user-status-tag {
    display: inline-block;
    height: 20px;
    padding: 0 8px;
    border-radius: 200px;
    background-color: #3e3f5e;
    color: #fff;
    font-size: 0.75rem;
    font-weight: 700;
    line-height: 20px;
    text-transform: uppercase;
    position: relative;
    top: -3px;

    &.online {
      background-color: #1df377;
    }

    &.offline {
      background-color: #f9515c;
    }

    &.away {
      background-color: #adafca;
    }
  }

  .user-status-icon {
    opacity: 0.4;
    position: absolute;
    top: 10px;
    right: 0;
  }

  .action-request-list {
    position: absolute;
    top: 2px;
    right: 0;
  }
}
