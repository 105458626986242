@import '../../assets/styles/variables.scss';

.recruiter-list {
  display: -ms-flexbox;
  display: flex;
  gap: 10px;

  .recruiter-item {
    display: flex;
    align-items: center;
    justify-content: center;

    background-color: #214f87;
    color: #fff;

    border-radius: 100%;

    width: 53px;
    height: 53px;

    position: relative;

    .recruiter-list-hidden {
      position: absolute;
      top: calc(100% + 10px);
      left: 50%;
      transform: translateX(-50%);

      background-color: rgba($color: #000000, $alpha: 0.8);

      padding: 10px;
      border-radius: 4px;
      z-index: 999;

      display: flex;
      flex-direction: column;

      gap: 5px;

      & span {
        color: #fff;
        white-space: nowrap;

        font-size: 10px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
      }
    }
  }
}
