.candidate-preview-wrapper {
  .border-right {
    border-right: 1px solid rgb(234, 234, 245);
  }

  .user-preview {
    padding: 0 0px 32px;

    .user-avatar {
      position: absolute;
      color: #fff;
      left: 50%;
      transform: translate(0, -50%);
      font-weight: 900;
      font-size: 2rem;
      z-index: 1;
    }

    .hexagon {
      width: 30%;
      position: relative;
      left: 50%;
      transform: translate(-50%, -20%);

      text {
        font-size: 2rem;
        font-weight: 700;
        text-anchor: middle;
        fill: #fff;
      }

      polygon {
        stroke-width: 8;
        stroke: #fff;
        fill: #114d8c;
      }
    }

    .user-profile {
      font-size: 0.875rem;
      font-weight: 700;
      line-height: 1em;
      text-align: center;
      margin: 0;
    }

    .user-info {
      margin-top: 6px;
      margin-bottom: 0px;
      font-size: 0.75rem;
      font-weight: 500;
      text-transform: none;
      color: #adafca;
      text-align: center;
      line-height: 1em;
    }

    .user-preview-header {
      height: 70px;
      width: 100%;
      background-repeat: no-repeat !important;
      background-size: cover !important;
      background-color: #c1c1c2;
      border-top-left-radius: 12px;
    }
  }

  .sidebar-menu-body {
    padding: 12px 0px 12px 60px;
    border-top: 1px solid rgb(234, 234, 245);
  }

  .popup-box-sidebar-footer {
    border-top: 1px solid rgb(234, 234, 245);
    padding: 28px;
  }

  .content {
    position: relative;
    padding: 32px 28px;

    h1 {
      font-size: 1rem;
      font-weight: 700;
    }

    p {
      margin-top: 36px;
      font-size: 80%;
      font-weight: 400;
      line-height: 1em;
    }
  }
}
