/*-------------------------
    10. SECTION BANNER
-------------------------*/

.section-banner {
  min-height: 160px;
  padding: 52px 60px 0 200px;
  border-radius: 12px;
  background: url('../../assets/images/background/banner-background.png')
    no-repeat center;
  position: relative;

  .section-banner-icon {
    position: absolute;
    bottom: 0;
    left: 0;
  }

  .section-banner-title {
    font-size: 2.25rem;
    font-weight: 700;
    color: #fff;
    margin: 0;
  }

  .section-banner-text {
    margin-top: 10px;
    font-size: 1rem;
    font-weight: 500;
    color: #fff;
  }

  @media screen and (max-width: 960px) {
    height: 86px;
    min-height: auto;
    padding: 18px 28px 0 108px;

    .section-banner-icon {
      width: 92px;
      height: 86px;
    }

    .section-banner-title {
      font-size: 1.5rem;
    }

    .section-banner-text {
      margin-top: 6px;
      font-size: 0.75rem;
    }
  }
}
