.page-wrapper {
  background: url('../../assets/images/background/404.png');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: right top;
  width: 100%;
  min-height: 100vh;
  display: grid;
  grid-template-columns: 1.5fr 1fr;
  grid-template-rows: 1fr;

  @media (max-width: 1275px) {
    grid-template-columns: 1.2fr 1.3fr;
  }

  @media (max-width: 975px) {
    grid-template-columns: 1fr 1.5fr;
  }

  @media (max-width: 875px) {
    grid-template-columns: 0.8fr 1.7fr;
  }

  @media (max-width: 768px) {
    grid-template-columns: 0fr 2fr;
  }

  & .content {
    grid-column-start: 2;
    width: 90%;
    margin: 0 auto;
    min-height: 100vh;
    display: flex;
    align-items: center;

    h1 {
      font-family: 'Quicksand';
      font-style: normal;
      font-weight: bold;
      font-size: 90px;
      line-height: 22px;
      letter-spacing: -0.41px;
    }

    p {
      font-family: 'Quicksand';
      font-style: normal;
      font-weight: 500;
      font-size: 20px;
      line-height: 30px;
    }

    button {
      width: 50%;
    }
  }
}
