@import '../../../assets/styles/variables.scss';

.warning {
  display: flex;
  align-items: center;
  padding: 10px 15px;
  border: 1px solid $yellow-dark-color;
  background-color: $yellow-light-color;
  border-radius: 8px;
  width: fit-content;
  margin-top: -5px;

  p {
    color: $yellow-dark-color;
    margin: 0;
    width: auto;
    margin-left: 10px;
  }
}
