.list-workflows-content {
  display: grid;
  grid-gap: 30px;
  grid-template-columns: repeat(auto-fit, 284px);
  justify-content: center;
}

.modal-confirmation {
  .popup-box {
    max-width: 400px !important;
  }

  .modal-confirmation-content {
    padding: 0 32px;
    margin-bottom: 30px;

    .content {
      font-weight: 600;
      font-size: 16px;
      line-height: 20px;
    }

    .danger {
      background-color: #eb5757;
    }
  }
}
