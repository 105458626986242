.date-picker-label {
  z-index: 9;
}

.react-datepicker-popper {
  z-index: 100;
}

.react-datepicker-wrapper {
  width: 100%;
  .react-datepicker__input-container {
    position: relative;
    &:before {
      position: absolute;
      top: 14px;
      right: 18px;
      background-image: url('../../assets/images/icons/calendar-icon.png');
      content: ' ';
      width: 20px;
      height: 20px;
    }
  }
}

.react-datepicker__navigation {
  &.react-datepicker__navigation--previous,
  &.react-datepicker__navigation--next {
    top: 10px !important;
    width: auto !important;
  }
}

.react-datepicker__header {
  background-color: #615dfa;
  * {
    color: white;
  }
}
