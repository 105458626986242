.theme-lugarh {
  h1 {
    color: var(--primary-color);
  }
  h2 {
    color: var(--primary-color-dark);
  }
}

main {
  background: url('../../assets/images/background/landing-background.png');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: left center;
  overflow-x: hidden;
  height: 100vh;

  .landing-info {
    position: relative;
    margin-right: 60px;
    margin-top: 150px;
    h1,
    h2,
    p {
      color: #fff;
      text-align: center;
      text-transform: uppercase;
    }

    h2 {
      font-size: 1.5rem;
      font-weight: 500;
    }

    h1 {
      font-family: 'Titillium Web', sans-serif;
      font-size: 6.5rem;
      font-weight: 900;

      @media (max-width: 500px) {
        font-size: 3.75rem;
      }
    }

    p {
      font-size: 1.125rem;
      line-height: 1.3333333333em;
      text-transform: initial;
    }
  }
}

.splash-background-area {
  position: relative;
  width: 570px;
  height: 570px;
}

.splash-background {
  position: absolute;
  height: 550px;
  right: -25px;
}

.splash-background-people {
  position: absolute;
  right: -26px;
  top: 270px;
  height: 290px;
}

.first-paragraph {
  color: #f985f3 !important;
  margin: -40px;
}

.second-paragraph {
  color: #61d3ff !important;
  font-weight: bold;
  font-size: 33px !important;
  margin-top: 90px;
}

.paragraph-area {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  align-content: stretch;
  width: 200px;
  margin: 0 auto;
}

.recrud-logo {
  width: 280px;
}

// RESPONSIVE MODE
@media screen and (max-width: 400px) {
  main .landing-info {
    position: relative;
    margin-right: 0px;
    margin-top: 150px;
  }

  .splash-background {
    right: -170px;
  }

  .splash-background-people {
    display: none;
  }
}
