.theme-lugarh {
  &.mui-modal {
    .candidate-filter-container {
      padding: 0 16px 16px;
      margin: 0;
      overflow-y: initial;
    }
  }
}

// TODO: PARA DELETAR DEPOIS QUE TROCAR O THEMA DESSA PAGINA
.candidates-filter {
  .mui-modal__unique-button {
    background-color: var(--primary-color);

    &:hover {
      background-color: var(--primary-color-light);
    }

    &:active {
      background-color: var(--primary-color-dark);
    }
  }
}
