@import '../../assets/styles/variables';

button {
  width: 100%;
  border-radius: 3px;
  font-size: 1rem;
  font-weight: 700;
  display: inline-block;
  height: 48px;
  background-color: #c1c1c1;
  color: #fff;
  font-size: 0.875rem;
  font-weight: 700;
  text-align: center;
  line-height: 24px;
  cursor: pointer;
  transition:
    background-color 0.2s ease-in-out,
    color 0.2s ease-in-out,
    border-color 0.2s ease-in-out,
    box-shadow 0.2s ease-in-out;
  box-shadow: 3px 5px 10px 0 rgba(62, 63, 94, 0.2);

  &:disabled {
    background-color: #eef2f8;
    box-shadow: none;
    cursor: not-allowed;
    color: #b2afd3;
  }

  &.no-shadow {
    box-shadow: none;
  }

  &.full {
    width: 100%;
  }

  &.medium {
    height: 54px;
    line-height: 54px;
  }

  &.small {
    height: 40px;
    font-size: 0.75rem;
    line-height: 40px;
  }

  &::hover {
    color: #fff;
    background-color: #2e2e47;
  }

  &.void {
    background-color: transparent;
    color: #adafca;
    box-shadow: none;

    & .button-icon {
      fill: #adafca;
    }

    &:hover {
      color: #fff;
      background-color: #2e2e47;

      & .button-icon {
        fill: #fff;
      }
    }

    &.void-primary:hover {
      background-color: #23d2e2;
    }

    &.void-secondary:hover {
      background-color: #615dfa;
    }
  }

  &.white-solid {
    background-color: #fff;
    color: #3e3f5e;
  }

  &.white {
    background-color: transparent;
    border: 1px solid #dedeea;
    color: #adafca;
    box-shadow: none;

    & .button-icon {
      fill: #adafca;
    }

    &:hover {
      color: #fff;
      background-color: #2e2e47;
      border-color: #2e2e47;
    }

    &.white-tertiary:hover {
      background-color: #fd4350;
      border-color: #fd4350;
      box-shadow: 4px 7px 12px 0 rgba(253, 67, 80, 0.2);
    }
  }

  &.primary {
    background-color: $primary-color;
    box-shadow: 4px 7px 12px 0 rgba(35, 210, 226, 0.2);

    &:hover {
      background-color: $secondary-color;
    }
  }

  &.secondary {
    background-color: #615dfa;
    box-shadow: 4px 7px 12px 0 rgba(97, 93, 250, 0.2);

    &:hover {
      background-color: #5753e4;
    }
  }

  &.primary-custom {
    background-color: #c1c1c2;
    color: white;
  }

  &.tertiary {
    background-color: #fd4350;
    box-shadow: 4px 7px 12px 0 rgba(253, 67, 80, 0.2);

    &:hover {
      background-color: #dd343f;
    }
  }

  &.twitch {
    background-color: #7b5dfa;
    box-shadow: 4px 7px 12px 0 rgba(123, 93, 250, 0.2);

    &:hover {
      background-color: #6a4fdf;
    }
  }

  &.twitter {
    background-color: #1abcff;
    box-shadow: 3px 5px 10px 0px rgba(26, 188, 255, 0.2);

    &:hover {
      background-color: #0aabed;
    }
  }

  &.add-field-button {
    width: 128px;
    margin-top: 40px;
  }

  &.padded {
    padding: 0 24px;
  }

  & .button-icon {
    fill: #fff;
    transition: fill 0.2s ease-in-out;

    &.spaced {
      margin-right: 6px;
    }
  }

  &.with-only-icon {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: center;
    justify-content: center;
    -ms-flex-align: center;
    align-items: center;
  }
}

.button-square {
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}
