.demo-box {
  padding-top: 48px;
  border-radius: 12px;
  height: 100%;
  background-color: #fff;
  box-shadow: 0 0 40px 0 rgba(94, 92, 154, 0.06);
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-width: 180px;
  padding-bottom: 10px;
  .name {
    min-height: 96px;
  }

  #job-apply-button {
    position: relative !important;
    margin: 10px !important;
  }

  .select-wrapper {
    padding: 0 10px;
  }

  .select-candidate {
    position: absolute;
    top: 10px;
    z-index: 10;
    left: 10px;
  }

  .user-stat {
    button {
      display: flex;
      -ms-flex-pack: center;
      justify-content: center;
      -ms-flex-align: center;
      align-items: center;
      width: 40px;
      height: 40px;
      border: 1px solid #dedeea;
      border-radius: 10px;
      color: #adafca;
      font-size: 0.75rem;
      font-weight: 700;
      cursor: pointer;
      box-shadow: none;
      transition:
        border-color 0.2s ease-in-out,
        background-color 0.2s ease-in-out,
        color 0.2s ease-in-out,
        transform 0.2s ease-in-out;
      background-color: transparent;

      &:hover {
        color: #fff;
        transform: translate(0, -4px);
      }
    }
  }

  &::before {
    content: '';
    width: 100%;
    height: 48px;
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
    background-color: rgba(193, 193, 193, 0.7);
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
  }

  .demo-box-icon-wrap {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: center;
    justify-content: center;
    -ms-flex-align: center;
    align-items: center;
    width: 68px;
    height: 68px;
    border-radius: 50%;
    background-color: #fff;
    margin: -26px auto 0;
    position: relative;
    z-index: 2;

    .demo-box-icon {
      fill: #3e3f5e;
    }
  }

  .demo-box-title,
  .demo-box-text {
    text-align: center;
    margin-bottom: 0px;
  }

  .demo-box-title {
    margin-top: 8px;
    font-size: 1rem;
    font-weight: 700;
    padding: 0 28px;
  }

  .demo-box-text {
    margin-top: 4px;
    font-size: 0.75rem;
    font-weight: 500;
    color: #3e3f5e;
    transition:
      opacity 0.4s ease-in-out,
      visibility 0.4s ease-in-out;
  }
}

.candidate-box {
  min-height: 365px;
}

.candidate-box-list {
  min-height: 185px;
}

.user-stats {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
  justify-content: center;

  .user-stat {
    position: relative;
    padding: 0 15px;

    &::after {
      content: '';
      width: 1px;
      height: 20px;
      background-color: #eaeaf5;
      position: absolute;
      top: 6px;
      right: 0;
    }

    &:last-child::after {
      display: none;
    }

    .user-stat-text {
      font-weight: 700;
      text-transform: uppercase;
      text-align: center;
      margin-top: 10px;
      color: #adafca;
      font-size: 0.6875rem;
    }
  }
}
.button {
  &.void {
    &.candidate-new {
      background-color: #eef2f8;
      width: 90%;
      margin: 10px;
      position: absolute;
      bottom: 0;
      left: 0;

      &:hover {
        background-color: rgba(193, 193, 193, 0.7);
      }
    }
    &.candidate-new-list {
      background-color: #eef2f8;
      width: 100%;
      height: 60px;
      display: flex;
      gap: 10px;
      align-items: center;
      justify-content: center;

      &:hover {
        background-color: rgba(193, 193, 193, 0.7);
      }
    }
  }
}
