.DraftEditor-root {
  border: 1px solid #dedeea;
  margin-left: 1px;
  margin-top: 10px;
  color: #3e3f5e;
  border-radius: 10px;
  min-height: 163px !important;
  padding-left: 10px;
  transition: border-color 0.2s ease-in-out;
}

.focused {
  .DraftEditor-root {
    border-color: #615dfa;
  }
  .label {
    top: 111px;
    font-size: 0.75rem;
  }
}

.with-content {
  .label {
    top: 111px;
    font-size: 0.75rem;
  }
}

.label {
  color: #adafca;
  font-weight: 600;
  font-family: 'Quicksand', sans-serif;
  font-size: 0.875rem;
  background-color: white;
  padding: 0px 5px 0px 5px;
  transition: all 0.3s !important;
  z-index: 9;
}

.rdw-editor-main {
  padding-bottom: 1px;
}

em {
  font-style: italic;
}
