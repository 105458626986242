@import '../../assets/styles/variables.scss';

.candidate-detail-header {
  border-radius: 12px;
  background-color: #fff;
  box-shadow: 0 0 40px 0 rgba(94, 92, 154, 0.06);
  position: relative;
  overflow-wrap: anywhere;

  .apply-to-job {
    width: 100% !important;
    height: auto !important;
    background-color: #ffb300;
    padding: 14px 0;
    color: white;
  }

  .avatar {
    border: 5px solid white;
    height: 80px;
    width: 80px;
    margin-top: -40px;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    font-weight: bold;
    font-size: 40px;
    background-color: $linkedin-color;

    z-index: 1;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
  }

  .figure {
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
    height: 100px;
    width: 100%;
    background-color: #e3eef9;
  }

  .hexagon {
    width: 130px;
    position: absolute;
    left: 50%;
    transform: translate(-50%, 0%);
  }

  .user-profile {
    color: #5a5a5a;
    font-weight: 600;
    line-height: 1em;
    font-size: 1.5rem;
  }

  .content {
    margin: 20px 0;
    padding: 0 15px;

    .order-1 {
      order: 2;
      @media (max-width: 768px) {
        order: 1;
      }
    }

    .order-2 {
      order: 1;
      @media (max-width: 768px) {
        order: 2;
      }
    }

    .buttons {
      @media (max-width: 768px) {
        justify-content: center !important;
      }
    }

    .social-media {
      width: 40px;
      height: 40px;
      margin: 0 5px;
      transition:
        transform 0.2s ease-in-out,
        -webkit-transform 0.2s ease-in-out;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 10px;

      svg {
        width: 16px;
        height: 16px;
        fill: #fff;
      }

      img {
        width: 16px;
        height: 16px;
      }

      &:hover {
        transform: translate(0, -4px);
      }

      &.whatsapp {
        background-color: $whatsapp-color;
      }

      &.email-button {
        background-color: $email-button-color;
      }

      &.twitter {
        background-color: $twitter-color;
      }

      &.facebook {
        background-color: $facebook-color;
      }

      &.linkedin {
        background-color: $linkedin-color;
      }
    }
  }
}
