.career-switch-option {
  position: relative;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: stretch;
  align-content: stretch;
  .career-title {
    font-size: 0.875rem;
    font-weight: 700;
  }
  .career-text {
    margin-top: 4px;
    color: #8f91ac;
    font-size: 0.875rem;
    font-weight: 500;
    line-height: 1.4285714286em;
  }
  .career-switch-area {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    align-content: stretch;
  }
  .career-form-switch {
    position: inherit;
    margin: 0 100px 0 20px;
  }
  .career-form-switch-label {
    font-size: 0.875rem;
    font-weight: 700;
    line-height: 26px;
    color: #3e3f5e;
  }
  .career-form-switch-area {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: center;
    align-content: center;
  }

  .career-switch-button {
    align-items: flex-start;
  }
}
@media only screen and (max-width: 400px) {
  .career-switch-option {
    margin-bottom: 20px;
    .career-description-area {
      width: 244px;
    }
    .career-switch-area {
      justify-content: space-between;
      width: 244px;
    }
  }
}
@media only screen and (min-width: 667px) {
  .career-switch-option {
    margin-bottom: 20px;
    .career-description-area {
      width: 100%;
    }
    .career-switch-area {
      justify-content: flex-start;
      width: 100%;
      padding-left: 2.5%;
    }
  }
}
@media only screen and (min-width: 991px) {
  .career-switch-option {
    .career-description-area {
      width: 70%;
    }
    .career-switch-area {
      width: 30%;
    }
  }
}
