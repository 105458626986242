.checkbox-line {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -ms-flex-align: center;
  align-items: center;

  .checkbox-line-text {
    color: #00c7d9;
    font-size: 0.875rem;
    font-weight: 500;
  }
}

.checkbox-wrap {
  position: relative;
  .checkbox-info {
    margin-top: 12px;

    .checkbox-info-text {
      font-size: 0.875rem;
      font-weight: 500;
      line-height: 1.4285714286em;
    }
  }

  &.checkbox-rating {
    .rating-list {
      position: absolute;
      top: 6px;
      left: 34px;
      pointer-events: none;
    }

    label {
      padding-left: 104px;
    }
  }

  input[type='checkbox'],
  input[type='radio'] {
    display: none;
  }

  input[type='checkbox']:checked + .checkbox-box,
  input[type='radio']:checked + .checkbox-box {
    background-color: #23d2e2;
    border-color: #23d2e2;

    &.check {
      background-color: #1976d2;
      border-color: #1976d2;
    }
  }

  input[type='checkbox']:checked + .checkbox-box.round,
  input[type='radio']:checked + .checkbox-box.round {
    border: 6px solid #23d2e2;
    background-color: #fff;
  }

  input[type='checkbox']:checked + .checkbox-box .icon-cross,
  input[type='radio']:checked + .checkbox-box .icon-cross {
    fill: #fff;
  }

  .icon-check {
    width: 15px;
    height: 15px;
  }

  label {
    padding-left: 34px;
    font-size: 0.875rem;
    font-weight: 700;
    line-height: 22px;
    cursor: pointer;
    min-width: 22px;
    height: 22px;
  }

  &.small {
    .checkbox-box {
      width: 16px;
      height: 16px;
      border-radius: 4px;

      .icon-check {
        width: 12px;
        height: 12px;
      }

      .icon-cross {
        width: 8px;
        height: 8px;
      }

      &.round {
        border-radius: 50%;
      }
    }

    label {
      padding-left: 24px;
      font-size: 0.75rem;
      line-height: 16px;
      width: 16px;
      height: 16px;
    }
  }

  .checkbox-box {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: center;
    justify-content: center;
    -ms-flex-align: center;
    align-items: center;
    width: 22px;
    height: 22px;
    border: 3px solid rgba(0, 0, 0, 0.6);
    border-radius: 6px;
    background-color: #fff;
    pointer-events: none;
    position: absolute;
    top: 0;
    left: 0;
    transition:
      border-color 0.2s ease-in-out,
      background-color 0.2s ease-in-out;

    &.round {
      border-radius: 50%;
    }

    .icon-cross {
      fill: transparent;
      transition: fill 0.2s ease-in-out;
    }
  }
}
