.boards {
  display: flex;
  margin-top: 32px;

  .board {
    background: #fff;
    border: 1px solid #fff;
    border-radius: 10px;
    margin: 0 16px;
    width: 100%;

    .board-header {
      display: flex;
      align-items: center;
      flex-wrap: wrap;

      .tooltip-info {
        width: 20px;
        height: 20px;
        border-radius: 50%;
        background: #3e3f5e;
        margin-top: 16px;
        margin-left: 15px;
        color: #fff;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .title {
        font-weight: bold;
        font-size: 20px;
        line-height: 26px;
        color: #3e3f5e;
        padding: 16px 0 0 16px;
        margin: 0;
      }
    }
  }

  .dropzone {
    padding: 0 16px;
    width: 100%;
    height: 100%;
  }

  .card {
    background-color: #fff;
    width: 100%;
    margin: 25px 0;
    border-radius: 10px;
    padding: 10px;
    height: 50px;
    box-shadow:
      0px 4px 4px rgba(50, 50, 71, 0.08),
      0px 4px 8px rgba(50, 50, 71, 0.06);
    cursor: grab;
    display: flex;
    flex-direction: row;
    align-items: center;
    border: none;

    &:active {
      cursor: grabbing;
    }

    p {
      font-weight: bold;
      font-size: 14px;
      line-height: 18px;
      color: #00467d;
      margin-left: 10px;
    }

    &.is-dragging {
      opacity: 0.3;
      cursor: grabbing;
    }

    .user-avatar-content {
      div,
      svg {
        width: 35px;
        height: 35px;
        font-size: 25px;
      }
    }
  }
}

.highlight {
  background-color: #5468da08;
}

.card,
.dropzone {
  transition: 400ms;
}

.over {
  background-color: #00000011;
}
