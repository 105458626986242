.joblist {
  &__button {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    font-weight: 700;
    width: 268px;
    align-self: flex-end;
    max-height: 48px;
    gap: 8px;
    padding: 13px 24px;
    background-color: #6ac425;
  }
}
