.success-text {
  font-family: Quicksand;
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 20px;
  display: flex;
  align-items: center;

  /* Text-color */
  color: #3e3f5e;
  margin-left: 28px;
  margin-right: 28px;
}

.recrud-link-container {
  display: flex;
  justify-content: center;
  .recrud-link {
    font-family: Quicksand;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 26px;
    display: flex;
    align-items: center;

    /* link-colors */
    color: #00c7d9;
  }
}
