body {
  overflow-x: hidden;
}

.scroll-smooth {
  scroll-behavior: smooth;
}

.onboarding-overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 999999;
  background-color: #323232c9;
}

.onboarding-modal {
  background: #fff;
  border-radius: 5px;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
  max-width: 400px;
  width: 90%;
  z-index: 9999999;

  &.without-popper {
    left: 50%;
    top: 50%;
    position: absolute;
    transform: translate(-50%, -50%);
  }

  &.center-modal {
    position: absolute;
    transform: translate(-50%, -50%);
    left: 50%;
    top: 50%;
  }

  .onboarding-title {
    padding: 1em;
    align-items: center;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    display: flex;
    line-height: 2em;
    padding-bottom: 0;

    h3 {
      color: #3e3f5e;
      display: flex;
      font-size: 1.4rem;
      font-weight: 700;
      word-wrap: break-word;

      margin: 0;
      padding: 0;
    }
  }

  .onboarding-body {
    padding: 0 1em;

    p {
      color: #3e3f5e;
      text-align: left;
      font-size: 1rem;
      font-weight: 600;
    }
  }

  .onboarding-actions {
    padding: 1em;

    button {
      border-radius: 10px;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #fff;
    }

    .btn-gray {
      background: #aeb0ca;
    }

    .btn-default {
      background: #50d2fb;
    }
  }
}
