.workflow-card-wrapper {
  border-radius: 12px;
  background-color: #fff;
  box-shadow: 0 0 40px 0 rgba(94, 92, 154, 0.06);
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  min-height: 300px;
  position: relative;
  max-width: 284px;
  min-width: 284px;

  &.add {
    justify-content: space-between !important;
  }

  .workflow-card-body {
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
    margin-top: 50px;
    margin-bottom: 65px;
  }

  .hexagon {
    width: 30%;
    max-width: 100px;
    position: absolute;
    left: 50%;
    transform: translate(-50%, 55%);

    polygon {
      stroke-width: 8;
      stroke: #fff;
    }
  }

  .clickable {
    cursor: pointer;
  }

  & .btn-add-new {
    color: #adafca;
    box-shadow: none;
    background-color: #eef2f8;

    &:hover {
      background-color: #efa107;
      color: #fff;
    }
  }

  .bl-1 {
    width: 1px;
    height: 30px;
    margin-top: 9px;
    background-color: #eaeaf5;
  }

  & .btn-edit {
    color: #adafca;
    box-shadow: none;
    background-color: transparent;

    &:hover {
      background-color: #615dfa;
      color: #fff;
    }
  }

  & .btn-delete {
    color: #fff;
    box-shadow: none;
    background-color: #eb5757;
    width: 48px;
    height: 48px;

    &.disabled {
      background-color: #8e8e93;

      &:hover {
        background-color: #8e8e93;
        color: #fff;
      }
    }

    &:hover {
      background-color: #dd4f4f;
      color: #fff;
    }
  }

  .header-btn {
    max-width: 50px;
    align-items: flex-end;
    margin: 10px;
  }

  .btn-duplicate {
    color: #adafca;
    box-shadow: none;
    background-color: transparent;

    &:hover {
      background-color: #a8ebd1;
      color: #fff;
    }
  }

  .workflow-card-content {
    padding: 15px;
    margin-top: 40px;

    .title {
      color: #3e3f5e;
      font-weight: 700;
      font-size: 1.125rem;
      margin-top: -30px;
      word-break: break-word;
    }

    .location {
      font-size: 0.6875rem;
      color: #adafca;
      font-weight: 700;
      margin-top: 10px;
      text-transform: uppercase;
      word-break: break-word;
    }

    .workspaces {
      font-size: 14px;
      margin-top: 18px;
      margin-bottom: 0;
      color: #127eff;
      font-weight: 700;
      text-transform: uppercase;
      cursor: pointer;
    }
  }

  .workflow-card-header {
    height: 70px;
    width: 100%;
    background-repeat: no-repeat !important;
    background-size: cover !important;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    &.add-new {
      background: url('../../assets/images/background/grey.png');
    }

    &.active {
      background: url('../../assets/images/background/green.png');
    }
  }

  .footer {
    border-top: 1px solid #eaeaf5;
    background-color: #fcfcfd;
    bottom: 0;
    width: 100%;
    position: absolute;

    &.grid {
      display: grid;
      grid-template-columns: 2.2fr 0.8fr;
      grid-template-rows: 1fr;
      align-items: center;
      grid-gap: 12px;

      &.btn-delete {
        margin-right: 6px;
      }
    }
  }
}

.list-items-modal {
  .mui-modal__list-title {
    font-family: 'Montserrat', sans-serif;
    font-optical-sizing: auto;
    font-style: normal;
  }
}
