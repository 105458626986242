.theme-lugarh {
  .loader {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.3);
    z-index: 99999999;
    overflow-y: hidden;
    display: flex;
    align-items: center;
    justify-content: center;

    &__bars {
      display: -ms-flexbox;
      display: flex;
      -ms-flex-pack: center;
      justify-content: center;
      height: 40px;
    }

    &__bar {
      border: 2px solid var(--primary-color);
      width: 4px;
      height: 100%;
      margin-right: 4px;
      border-radius: 200px;
      -webkit-transform: scaleY(0.2);
      transform: scaleY(0.2);
      -webkit-animation: loader-bars 0.5s infinite alternate ease-in;
      animation: loader-bars 0.5s infinite alternate ease-in;

      &:last-child {
        margin-right: 0;
      }

      &:nth-child(2) {
        -webkit-animation-delay: 0.1s;
        animation-delay: 0.1s;
      }

      &:nth-child(3) {
        -webkit-animation-delay: 0.2s;
        animation-delay: 0.2s;
      }

      &:nth-child(4) {
        -webkit-animation-delay: 0.3s;
        animation-delay: 0.3s;
      }

      &:nth-child(5) {
        -webkit-animation-delay: 0.4s;
        animation-delay: 0.4s;
      }

      &:nth-child(6) {
        -webkit-animation-delay: 0.5s;
        animation-delay: 0.5s;
      }

      &:nth-child(7) {
        -webkit-animation-delay: 0.6s;
        animation-delay: 0.6s;
      }

      &:nth-child(8) {
        -webkit-animation-delay: 0.7s;
        animation-delay: 0.7s;
      }
    }
  }
}

@-webkit-keyframes loader-bars {
  0% {
    -webkit-transform: scaleY(0.2);
    transform: scaleY(0.2);
  }

  100% {
    -webkit-transform: scaleY(1);
    transform: scaleY(1);
  }
}

@keyframes loader-bars {
  0% {
    -webkit-transform: scaleY(0.2);
    transform: scaleY(0.2);
  }

  100% {
    -webkit-transform: scaleY(1);
    transform: scaleY(1);
  }
}
