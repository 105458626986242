.theme-lugarh {
  &.user-modal {
    .mui-modal {
      &__box {
        width: 500px;
        height: 580px;
        position: relative;
      }

      &__content {
        height: 446px;
        overflow-y: scroll;
      }

      &__form {
        border-radius: initial;
        margin-bottom: 120px;
        padding-bottom: 0;

        .form__row {
          margin-top: 0;
          gap: 15px;
        }

        .form__field {
          &:first-child {
            margin-top: 0;
          }
        }

        select {
          height: 54px;
          padding: 0 18px;
        }
      }
    }

    .ws-modal__footer {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      border-radius: 0 0 4px 4px;
      background-color: white;
      padding: 20px;
      z-index: 1;

      .form__button {
        margin: 0;
      }
    }
  }
}
