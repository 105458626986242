.upload-box {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-align: center;
  align-items: center;
  height: 140px;
  justify-content: center;
  border-radius: 12px;
  background-color: #fff;
  box-shadow: 0 0 40px 0 rgba(94, 92, 154, 0.06);
  cursor: pointer;
  transition:
    box-shadow 0.25s ease-in-out,
    -webkit-transform 0.2s ease-in-out;
  transition:
    transform 0.2s ease-in-out,
    box-shadow 0.25s ease-in-out;
  transition:
    transform 0.2s ease-in-out,
    box-shadow 0.25s ease-in-out,
    -webkit-transform 0.2s ease-in-out;

  &:hover {
    box-shadow: 0 0 40px 0 rgba(94, 92, 154, 0.12);
    -webkit-transform: translate(0, -4px);
    transform: translate(0, -4px);

    & .icon {
      fill: #23d2e2;
    }
  }

  &.with-image {
    p {
      margin: 0;
    }
  }

  img {
    height: 80px;
    border-radius: 10px;
  }

  .icon {
    fill: #adafca;
    transition: fill 0.25s ease-in-out;
    max-width: 23.4px;
  }

  .title {
    font-size: 0.875rem;
    font-weight: 700;
  }

  .text {
    margin-top: 4px;
    color: #8f91ac;
    font-size: 0.75rem;
    font-weight: 500;
  }

  input {
    display: none;
  }
}
