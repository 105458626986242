/*--------------
    2. FORM
--------------*/
.form {
  width: 100%;
}

.form + .button {
  margin-top: 40px;
}

/*------------------
      3. FORM ROW
  ------------------*/
.form-row {
  width: 100%;
}

.form-row + .form-row {
  margin-top: 28px;
}

.form-row.space-between {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -ms-flex-align: center;
  align-items: center;
}

.form-row.space-between .form-item {
  width: auto;
}

.form-row.split {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
}

.form-row.split .form-item {
  margin-right: 16px;
}

.form-row.split .form-item:last-child {
  margin-right: 0;
}

.form-row.split .form-item > * {
  margin-bottom: 28px;
}

.form-row.split .form-item > *:last-child {
  margin-bottom: 0;
}

/*------------------
    4. FORM ITEM
------------------*/
.form-item {
  width: 100%;
}

.form-item.auto-width {
  width: auto;
}

.form-item.centered {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
}

.form-item.split {
  display: -ms-flexbox;
  display: flex;
}

.form-item.split.medium > * {
  margin-right: 16px;
}

.form-item.split.medium > *:last-child {
  margin-right: 0;
}

.form-item.split > * {
  width: 100%;
  margin-right: 12px;
}

.form-item.split > *:last-child {
  margin-right: 0;
}

/*------------------------------
      5. FORM INPUT DECORATED
  ------------------------------*/
.form-input-decorated {
  position: relative;
}

.form-input-decorated input[type='text'] {
  padding-right: 60px;
}

.form-input-decorated .form-input-icon {
  fill: #adafca;
  opacity: 0.6;
  pointer-events: none;
  position: absolute;
  top: 14px;
  right: 20px;
}
