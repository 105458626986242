.color-container {
  display: grid;
  grid-gap: 20px;
  grid-template-columns: repeat(auto-fit, minmax(220px, 1fr));

  .form-item {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
