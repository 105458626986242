.content-grid {
  .card-message {
    display: block;
    border-radius: 10px;
    border: none;
    padding: 22px;

    p {
      font-size: 16px;
      line-height: 20px;
      text-align: left;
    }

    .btn-link {
      font-size: 16px;
      line-height: 20px;
      width: auto;
      background-color: transparent;
      padding: 0;
      height: auto;
      box-shadow: none;
      text-decoration: underline;
    }
  }
  .grid-column {
    grid-gap: 10px !important;
  }
}
