.user-avatar-content {
  svg {
    width: 45px;
    height: 45px;
    border-radius: 50%;
    position: absolute;
    z-index: 2;
  }

  div {
    font-size: 30px;
    font-weight: bold;
    color: #fff;
    position: relative;
    z-index: 3;
    width: 45px;
    height: 45px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
  }
}

#tooltip {
  padding: 0 12px;
  border-radius: 200px;
  background-color: #3e3f5e;
  color: #fff;
  font-size: 0.75rem;
  font-weight: 700;
  line-height: 24px;
}
