.bb {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);

  p {
    font-size: 0.75rem;
    font-weight: 700;
    text-align: center;

    &:first-child {
      text-align: left;
    }
  }
}

.btn-delete {
  color: #fff;
  box-shadow: none;
  background-color: #eb5757;
  width: 48px;
  height: 48px;

  &.disabled {
    background-color: #8e8e93;
    cursor: not-allowed;

    &:hover {
      background-color: #8e8e93;
      color: #fff;
    }
  }

  &:hover {
    background-color: #dd4f4f;
    color: #fff;
  }
}

.modal-confirmation {
  .popup-box {
    max-width: 400px !important;
  }

  .modal-confirmation-content {
    padding: 0 32px;
    margin-bottom: 30px;

    .content {
      font-weight: 600;
      font-size: 16px;
      line-height: 20px;
    }

    .danger {
      background-color: #eb5757;
    }
  }
}

.modal-email-content {
  padding: 0 32px 12px;
}

.list.section-header-info {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60px;
  padding: 0 28px;
  border-radius: 0;
  box-shadow: 0 0 40px 0 rgba(94, 92, 154, 0.06);
  background-color: #e3eef9;
  width: 100%;

  position: absolute;
  top: 80px;
  left: 80px;
  right: 0;

  .section-title {
    font-size: 18px;
    font-weight: 700;
    color: #3084d6;
    display: flex;
    align-items: center;
    gap: 10px;
  }

  @media screen and (max-width: 960px) {
    -ms-flex-direction: column;
    flex-direction: column;
    height: auto;
    padding: 32px 28px;
  }
}

.section-header-info__container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  max-width: 1180px;
  width: 100%;
}
