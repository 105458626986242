.about-candidate-wrapper {
  .about-card {
    padding: 32px 28px;
    border-radius: 12px;
    background-color: #fff;
    box-shadow: 0 0 40px 0 rgba(94, 92, 154, 0.06);
    width: 100%;

    p {
      margin: 0;

      &.information {
        font-size: 0.875rem;
        line-height: 1.7142857143em;
        font-weight: 500;
        word-wrap: break-word;
      }

      &.personal-info {
        &.title {
          color: #5a5a5a;
          width: 80px;
        }

        font-size: 0.875rem;
        font-weight: 500;
        line-height: 1.4285714286em;
        word-wrap: break-word;
        overflow: hidden;
        overflow-wrap: break-word;
      }
    }

    .title {
      font-size: 1rem;
      font-weight: 700;
    }

    .content {
      margin-top: 36px;

      .d-flex {
        flex-wrap: wrap;
      }
    }

    .download-link {
      width: 100%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .download-link > span {
      color: #8f91ac !important;
      text-decoration: underline;
      cursor: pointer;
    }

    .download-link > span:hover {
      color: #3e3f5e !important;
    }
  }
}
