/*---------------------------------
  125. SWITCH OPTION LIST
---------------------------------*/
.list {
  .switch-option {
    margin-bottom: 26px;
    &:last-child {
      margin-bottom: 0;
    }
  }
}
/*---------------------------------
    126. SWITCH OPTION
---------------------------------*/
.switch-option {
  position: relative;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: stretch;
  align-content: stretch;
  .title {
    font-size: 0.875rem;
    font-weight: 700;
  }
  .text {
    margin-top: 4px;
    color: #8f91ac;
    font-size: 0.875rem;
    font-weight: 500;
    line-height: 1.4285714286em;
  }
  .switch-area {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-end;
    align-items: center;
    align-content: stretch;
  }
  .form-switch {
    position: inherit;
    margin: 0 100px 0 20px;
  }
  .form-switch-label {
    font-size: 0.875rem;
    font-weight: 700;
    line-height: 26px;
    color: #3e3f5e;
  }
  .form-switch-area {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    align-content: center;
  }
  .button {
    width: 80px;
    margin-top: 32px;
  }
  .meta {
    color: #8f91ac;
    font-size: 0.875rem;
    font-weight: 500;
    position: absolute;
    bottom: 12px;
    right: 0;
  }
  .meta .bold {
    color: #3e3f5e;
    font-weight: 700;
  }
}
.terms-of-use-option {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: flex-end;
  align-content: stretch;
  .title {
    font-size: 0.875rem;
    font-weight: 700;
  }
  .text {
    margin-top: 4px;
    color: #8f91ac;
    font-size: 0.875rem;
    font-weight: 500;
    line-height: 1.4285714286em;
  }
  .description-area-terms-of-use {
    width: 70%;
  }
  .terms-of-use-input-area {
    width: 30%;
  }
}
@media only screen and (max-width: 400px) {
  /*---------------------------------
    126. SWITCH OPTION
---------------------------------*/
  .switch-option {
    margin-bottom: 20px;
    .description-area {
      width: 244px;
    }
    .switch-area {
      justify-content: space-between;
      width: 244px;
    }
  }
  .terms-of-use-option {
    flex-wrap: wrap;
    .description-area-terms-of-use {
      width: 100%;
    }
    .terms-of-use-input-area {
      width: 100%;
    }
  }
}
@media only screen and (min-width: 667px) {
  /*---------------------------------
    126. SWITCH OPTION
---------------------------------*/
  .switch-option {
    margin-bottom: 20px;
    .description-area {
      width: 100%;
    }
    .switch-area {
      justify-content: space-around;
      width: 100%;
    }
  }
}
@media only screen and (min-width: 991px) {
  /*---------------------------------
    126. SWITCH OPTION
---------------------------------*/
  .switch-option {
    .description-area {
      width: 70%;
    }
    .switch-area {
      width: 30%;
    }
  }
}
